/* RESPONSIBLE TEAM: team-data-foundations */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable ember/no-jquery */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { schedule } from '@ember/runloop';
import $ from 'jquery';
import { not, empty, and, alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import { equalToProperty, ternaryToProperty } from '@intercom/pulse/lib/computed-properties';

export default Component.extend({
  edit: false,
  showEditButton: false,
  isNotUpdating: true,
  attributeService: service('attributeService'),
  editDisabled: not('edit'),
  attributePresent: not('attributeIsEmpty'),
  attributeIsEmpty: empty('attribute'),
  attributePresentAndValid: and('attributePresent', 'attributeIsValid'),
  attributePresentAndInvalid: and('attributePresent', 'attributeNotValid'),
  attributeEqualToPreviousContent: equalToProperty('attribute', 'previousContent'),
  attributeNotEqualToPreviousContent: not('attributeEqualToPreviousContent'),
  attributeMap: alias('attributeService.attributeMap'),
  previousContentIsEmpty: empty('previousContent'),
  attributeNotValid: not('attributeIsValid'),
  displayName: ternaryToProperty('attributePresent', 'attribute', 'user.pseudonym'),
  inputPlaceholder: ternaryToProperty('attributePresent', 'attribute', 'placeHolderText'),
  isNotUpdatingAndAttributeHasChanged: and('attributeHasChanged', 'isNotUpdating'),
  alertDuration: 60000,
  focusOut() {
    this.send('requestUpdate');
  },
  mouseEnter() {
    this.set('showEditButton', true);
  },
  mouseLeave() {
    this.set('showEditButton', false);
  },
  actions: {
    enableEdit() {
      this.set('previousContent', this.attribute);
      this.set('edit', true);
      schedule('afterRender', this, () => {
        if (this.isDestroying) {
          return;
        }
        if ($('.twk__editable-attribute-input', this.element)) {
          $('.twk__editable-attribute-input', this.element)[0].select();
        }
      });
    },
    cancel() {
      this.set('edit', false);
      this.set(this.attributeKey, this.previousContent);
    },
    requestUpdate() {
      if (this.isNotUpdatingAndAttributeHasChanged) {
        this.send('update');
      } else {
        this.set('edit', false);
        this.set('isNotUpdating', true);
      }
    },
  },
});
