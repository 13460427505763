/* import __COLOCATED_TEMPLATE__ from './custom-object-composer.hbs'; */
/* RESPONSIBLE TEAM: team-actions */
import Component from '@glimmer/component';
import { BlocksDocument } from '@intercom/embercom-prosemirror-composer';
import ActionComposerConfig, {
  CustomActionsAttributeInfoResolver,
} from 'embercom/lib/actions/action-composer-config';
import { action } from '@ember/object';
import { tracked } from 'tracked-built-ins';
import { inject as service } from '@ember/service';
import { type Paragraph } from 'embercom/models/common/blocks/paragraph';

interface Args {
  value: any;
  includeAppAttributes: boolean;
  isDisabled?: boolean;
  localAttributes: any;
  attributeInfoResolver?: CustomActionsAttributeInfoResolver | null;
  isSingleLineComposer?: boolean;
  class?: string;
  errors?: any;
  placeholder?: string;
  onChange: (value: any) => void;
}

interface Signature {
  Args: Args;
}

export default class CustomObjectComposer extends Component<Signature> {
  @service declare appService: any;
  @service declare attributeService: any;

  @tracked blocksDoc: BlocksDocument;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.blocksDoc = new BlocksDocument(this.args.value);
  }

  get composerConfig() {
    let resolver =
      this.args.attributeInfoResolver ||
      new CustomActionsAttributeInfoResolver({
        attributes: this.appService.app.allowedAttributes,
        customObjectAttributes: this.attributeService.customObjectsAttributeDescriptors,
        conversationAttributes: this.attributeService.conversationAttributesForCustomAction,
        ticketTypeAttributes: this.attributeService.ticketTypeAttributes,
        includeCustomObjectAttributes: true,
        includeCustomActionAttributes: true,
        includeAppAttributes: this.args.includeAppAttributes,
        localAttributes: this.args.localAttributes,
      });

    return new ActionComposerConfig(
      this.args.isSingleLineComposer,
      this.args.isDisabled,
      resolver,
      this.args.placeholder,
    );
  }

  @action
  onChange(document: BlocksDocument) {
    this.blocksDoc = document;
    this.args.onChange(document);
  }

  get hasContent() {
    if (this.blocksDoc.blocks.length < 1) {
      return false;
    }
    if (this.blocksDoc.blocks.length > 1) {
      return true;
    }
    let firstBlock = this.blocksDoc.blocks[0];
    if (firstBlock.type === 'paragraph') {
      return (firstBlock as Paragraph).text !== '';
    }
    return true;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'WorkflowConnector::Builder::Body::Sections::Request::CustomObjectComposer': typeof CustomObjectComposer;
    'workflow-connector/builder/body/sections/request/custom-object-composer': typeof CustomObjectComposer;
  }
}
