/* import __COLOCATED_TEMPLATE__ from './map-component.hbs'; */
/* RESPONSIBLE TEAM: team-data-foundations */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-component-inheritance */
import { computed } from '@ember/object';
import MapComponent from 'embercom/components/map-component';
import DefaultAvatars from '@intercom/pulse/lib/default-avatars';
import dotMapMarkerTemplate from 'embercom/templates-raw/map/dot-map-marker';
import mapMarkerTemplate from 'embercom/templates-raw/map/map-marker';

let ProfileMapBoxComponent = MapComponent.extend({
  elementID: 'ProfileMapBox',
  classNames: ['pl_profile_mapbox_container'],
  maxZoom: 2,
  minZoom: 1,

  mapOptions: computed('maxZoom', 'minZoom', function () {
    return {
      attributionControl: false,
      zoomControl: false,
      maxZoom: this.maxZoom,
      minZoom: this.minZoom,
    };
  }),

  didInsertElement() {
    this._super(...arguments);
    this.initializeMap();
    this.loadPage();
    try {
      this.map.fitBounds(this.cluster.getBounds());
    } catch (e) {
      console.error(e);
    }
  },
  marker(user) {
    if (this.usePlainIcon) {
      return window.L.marker([user.geoip_data.latitude, user.geoip_data.longitude], {
        icon: window.L.divIcon(this.plainIcon(user)),
      });
    } else {
      return this._super(user);
    }
  },
  profileMarkerTemplate: computed('useDotIcon', function () {
    if (this.useDotIcon) {
      return dotMapMarkerTemplate;
    } else {
      return mapMarkerTemplate;
    }
  }),
  plainIcon(user) {
    user.default_avatar_url = DefaultAvatars.users[64];
    let html = this.profileMarkerTemplate(user.serialize());

    return {
      className: '',
      html,
      iconSize: [32, 32],
    };
  },
  addUsers(mappableUsers) {
    let markers = mappableUsers.map(function (user) {
      return this.marker(user).addTo(this.map);
    }, this);
    this.cluster.addLayers(markers);
  },
  loadPage() {
    this.addUsers(this.mappableUsers(this.users));
  },
});

export default ProfileMapBoxComponent;
