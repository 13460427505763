/* import __COLOCATED_TEMPLATE__ from './subscription.hbs'; */
/* RESPONSIBLE TEAM: team-data-foundations */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/no-classic-classes */
import { inject as service } from '@ember/service';
import { reads } from '@ember/object/computed';
// eslint-disable-next-line ember/no-classic-components
import Component from '@ember/component';
import { action } from '@ember/object';

export default Component.extend({
  tagName: '',
  unsubscribedFromEmails: reads('model.unsubscribed_from_emails'),
  notificationsService: service('notificationsService'),
  // eslint-disable-next-line @intercom/intercom/no-legacy-modal
  modalService: service(),

  successNotification(action) {
    return `${this.model.get('displayAs')} was successfully ${action}`;
  },

  errorNotification(action) {
    return `An error occurred while ${action} this user, please try again`;
  },

  closeModal: action(function () {
    this.modalService.closeModal();
  }),

  actions: {
    unsubscribe() {
      this.set('hasErrored', false);
      this.set('model.unsubscribed_from_emails', true);
      this.closeModal();
      this.model.saveUnsubscribedFromEmails().then(
        () => {
          this.notificationsService.notifyConfirmation(this.successNotification('unsubscribed'));
        },
        () => {
          this.notificationsService.notifyError(this.errorNotification('unsubscribing'));
          this.set('model.unsubscribed_from_emails', false);
        },
      );
    },
    resubscribe() {
      this.set('hasErrored', false);
      this.set('model.unsubscribed_from_emails', false);
      this.closeModal();
      this.model.saveUnsubscribedFromEmails().then(
        () => {
          this.notificationsService.notifyConfirmation(this.successNotification('resubscribed'));
        },
        () => {
          this.notificationsService.notifyError(this.errorNotification('resubscribing'));
          this.set('model.unsubscribed_from_emails', true);
        },
      );
    },
  },
});
