/* import __COLOCATED_TEMPLATE__ from './popover-menu-group.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { type StepGroupType } from 'embercom/objects/visual-builder/configuration/inserter-menu';
import type InserterMenuConfiguration from 'embercom/objects/visual-builder/configuration/inserter-menu';
import type { StepParameters } from 'embercom/objects/visual-builder/configuration/inserter-menu';
import { type InserterMenuItemType } from 'embercom/objects/visual-builder/configuration/inserter-menu-items-map';

interface Args {
  group: StepGroupType;
  shouldShowStepPaywalls?: boolean;
  forceOpen?: boolean;
  hidePopover: () => void;
  onSelectItem?: (
    stepParams: StepParameters,
    groupId?: string,
  ) => ReturnType<InserterMenuConfiguration['onSelectItem']>;
}

export default class PopoverMenuGroup extends Component<Args> {
  @tracked openSectionId = this._itemsVisibleByDefault ? 'section' : null;

  get _itemsVisibleByDefault() {
    let group = this.args.group;
    return !group.heading || group.isOpenByDefault || this.args.forceOpen;
  }

  doesItemSupportSelectedChannels(item: InserterMenuItemType, selectedChannels?: string[]) {
    return (
      !selectedChannels?.length ||
      !item.supportedChannels?.length ||
      item.supportedChannels?.some((supportedChannel) =>
        selectedChannels?.includes(supportedChannel),
      )
    );
  }

  unsupportedSelectedChannels(item: InserterMenuItemType, selectedChannels?: string[]) {
    if (!item.supportedChannels?.length || !selectedChannels?.length) {
      return [];
    }

    return (
      selectedChannels?.filter(
        (selectedChannel) => !item.supportedChannels?.includes(selectedChannel),
      ) ?? []
    );
  }

  get onSelectItem() {
    // Returning a noop if we didn't receive on select item
    return (
      this.args.onSelectItem ||
      (() => {
        console.warn(
          'No handler specified for item click, either add onSelectItem to each of the items or pass as an argument',
        );
      })
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::PopoverMenu::PopoverMenuGroup': typeof PopoverMenuGroup;
  }
}
