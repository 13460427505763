/* import __COLOCATED_TEMPLATE__ from './add-output.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import templateOnlyComponent from '@ember/component/template-only';
import type EditorState from 'embercom/objects/workflows/graph-editor/editor-state';
import { type OutputParam } from 'embercom/components/workflows/graph-editor/node-items/steps/fin-custom';

interface AddOutputArgs {
  outputParams: OutputParam[];
  editorState: EditorState;
  readOnly: boolean;
  onAddOutputParam: () => void;
  onRemoveOutputParam: (outputParam: OutputParam) => void;
}

const AddOutput = templateOnlyComponent<AddOutputArgs>();

export default AddOutput;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::NodeItems::Steps::FinCustom::AddOutput': typeof AddOutput;
    'workflows/graph-editor/node-items/steps/fin-custom/add-output': typeof AddOutput;
  }
}
