/* import __COLOCATED_TEMPLATE__ from './segment-search.hbs'; */
/* RESPONSIBLE TEAM: team-data-foundations */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-component-inheritance */
import SegmentSearchComponent from 'embercom/components/segment-search-component';

export default SegmentSearchComponent.extend({
  classNames: ['flex-grow', 'flex-shrink'],
});
