/* import __COLOCATED_TEMPLATE__ from './handoff-with-javascript.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */
import templateOnlyComponent from '@ember/component/template-only';
import type HandoffWithJavascriptType from 'embercom/models/operator/visual-builder/step/handoff-with-javascript';
import type EditorState from 'embercom/objects/workflows/graph-editor/editor-state';

export interface HandoffWithJavascriptArgs {
  step: HandoffWithJavascriptType;
  editorState: EditorState;
  readOnly: boolean;
}

interface Signature {
  Element: HTMLDivElement;
  Args: HandoffWithJavascriptArgs;
}

const HandoffWithJavascript = templateOnlyComponent<Signature>();

export default HandoffWithJavascript;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::NodeItems::Steps::HandoffWithJavascript': typeof HandoffWithJavascript;
  }
}
