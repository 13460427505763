/* import __COLOCATED_TEMPLATE__ from './note-composer-textarea-component.hbs'; */
/* RESPONSIBLE TEAM: team-data-foundations */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable @intercom/intercom/no-component-inheritance */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/no-observers */
/* eslint-disable ember/no-jquery */
import { computed, observer } from '@ember/object';
import $ from 'jquery';
import { notEmpty, not, readOnly } from '@ember/object/computed';
import ExpandingTextareaComponent from 'embercom/components/expanding-textarea-component';
import { trim } from '@intercom/pulse/lib/computed-properties';
import Run from 'embercom/lib/run';
import { inject as service } from '@ember/service';
import InboundActions from 'ember-component-inbound-actions/inbound-actions';

export default ExpandingTextareaComponent.extend(InboundActions, {
  intl: service(),
  intercomEventService: service(),
  notificationsService: service(),
  appService: service(),
  store: service(),
  app: readOnly('appService.app'),
  admin: readOnly('app.currentAdmin'),

  textareaContent: '',
  textareaPlaceholder: computed('intl.locale', function () {
    return this.intl.t('inbox.conversation-sidebar.cards.note-composer.add-note-placeholder');
  }),
  minHeight: 20,
  maxHeight: 'none',
  isEditing: false,
  isAnimating: false,
  isSaving: false,
  isDisabled: false,
  isTouch: false,
  inInbox: false,
  trimmedNote: trim('textareaContent'),
  trimmedNoteHasValue: notEmpty('trimmedNote'),
  trimmedNoteEmpty: not('trimmedNoteHasValue'),

  init() {
    this._super();
    // back up note body for editing
    this.set('originalBody', this.textareaContent);
  },

  updateNoteHasValue: observer({
    dependentKeys: ['textareaContent'],

    fn() {
      this.set('noteHasValue', this.trimmedNoteHasValue);
    },

    sync: false,
  }),
  keyDown(e) {
    if ((e.shiftKey || e.ctrlKey) && e.keyCode === 13) {
      return;
    }
    switch (e.keyCode) {
      case 13: // Enter
        e.preventDefault();
        this.set('isDisabled', true);
        this.send('save');
        break;
      case 27: // Escape
        this.send('cancel');
        break;
      default:
      // Do nothing
    }
  },
  focusIn() {
    this.stylePre(true);
  },
  focusOut() {
    // there's an "Add Note" button on touch devices...
    if (!this.isTouch && !this.isDisabled) {
      this.set('isDisabled', true);
      this.send('save');
    }
  },
  setMaxHeight() {
    // Do nothing
  },
  clearAndFocusOut() {
    this.set('textareaContent', '');
    $('textarea', this.element).blur();
  },
  _trackAddedNoteEvent() {
    let specificMetadata = {
      app_id_code: this.get('app.id'),
      admin_id: this.get('admin.id'),
      screen: `${this.get('notable.typeLabel')} profile`,
      userId: this.get('notable.id'),
    };

    let generalMetaData = {
      action: 'added',
      object: 'note',
      place: this.inInbox ? 'inbox' : `${this.get('notable.typeLabel').toLowerCase()}_profile`,
      owner: this.inInbox ? 'respond' : 'growth',
    };

    this.intercomEventService.trackEvent(
      `added-note-to-${this.get('notable.typeLabel').toLowerCase()}`,
      specificMetadata,
    );
    this.intercomEventService.trackEvent('note-added', generalMetaData);
  },
  styleTextarea() {
    this._super();
    // This is a temporary hack to make it look like the notes. I plan to merge
    // this with it's parent class which will remove the need for this.
    $('textarea', this.element).css({ 'line-height': '18px', color: 'inherit' });
  },
  resetState() {
    this.setProperties({ isEditing: false, isDisabled: false, isSaving: false });
  },
  actions: {
    save() {
      if (this.isEditing) {
        this.send('update');
      } else {
        this.send('create');
      }
    },
    create() {
      if (this.trimmedNoteHasValue) {
        let self = this;
        let note = this.store.createRecord('note', {
          body: this.trimmedNote,
          is_company: this.isCompany,
          model_id: this.get('notable.id'),
          admin_id: this.get('admin.id'),
        });

        if (!this.isCompany) {
          this._trackAddedNoteEvent();
        }

        note
          .save()
          .then(function () {
            self.set('isSaving', true);
            self.clearAndFocusOut();
            self.set('isDisabled', false);
            note.set('new', true);
            self.get('notable').get('notes').insertAt(0, note);
            Run.later(
              self,
              function () {
                self.set('isSaving', false);
              },
              300,
            );
          })
          .catch(function () {
            self.set('isDisabled', false);
          });
      } else {
        this.set('isDisabled', false);
      }
    },
    update() {
      if (this.trimmedNoteEmpty) {
        this.notificationsService.notifyError('Cannot save an empty note');
        this.send('cancel');
      } else if (this.get('note.hasDirtyAttributes')) {
        this.set('note.body', this.textareaContent);
        this.set('isSaving', true);
        this.note.save().then((_) => this.resetState());
      } else {
        this.resetState();
      }
    },
    cancel() {
      if (this.isEditing) {
        this.set('textareaContent', this.originalBody);
        this.set('isEditing', false);
      } else {
        this.clearAndFocusOut();
      }
    },
  },
});
