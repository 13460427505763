/* import __COLOCATED_TEMPLATE__ from './user-list.hbs'; */
/* RESPONSIBLE TEAM: team-data-foundations */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable @intercom/intercom/no-component-inheritance */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/use-brace-expansion */
/* eslint-disable ember/no-side-effects */
import { computed } from '@ember/object';
import { alias, equal, filterBy, notEmpty, readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { findBy, ternaryToProperty } from '@intercom/pulse/lib/computed-properties';
import UserOrCompaniesListComponent from 'embercom/components/base/user-or-companies-list';
import User from 'embercom/models/user';

export default UserOrCompaniesListComponent.extend({
  attributeService: service(),
  store: service(),
  intercomEventService: service(),
  identityVerificationRiskStateService: service(),
  onboardingHomeService: service(),
  intercomCallService: service(),
  searchActive: readOnly('search.hasValue'),

  displayedAttributes: readOnly('attributeService.displayedUserAttributes'),

  showCompanyAttributes: readOnly('app.companiesActive'),
  displayedUserAttributes: filterBy('displayedAttributes', 'isUser'),
  visibleDisplayedAttributes: ternaryToProperty(
    'showCompanyAttributes',
    'displayedAttributes',
    'displayedUserAttributes',
  ),
  lastRequestAtAttribute: findBy('visibleDisplayedAttributes', 'identifier', 'last_request_at'),
  lastRequestAtAttributeIsVisible: notEmpty('lastRequestAtAttribute'),
  userIdAttribute: findBy('visibleDisplayedAttributes', 'identifier', 'user_id'),
  emailAttribute: findBy('visibleDisplayedAttributes', 'identifier', 'email'),
  phoneAttribute: findBy('visibleDisplayedAttributes', 'identifier', 'phone'),
  whatsappNumberAttribute: findBy('visibleDisplayedAttributes', 'identifier', 'whatsapp_number'),

  showIdentityVerificationWarning: computed(
    'identityVerificationRiskStateService.shouldShowLowNotification',
    function () {
      let shouldShow = this.get('identityVerificationRiskStateService.shouldShowLowNotification');

      if (shouldShow) {
        this.send('trackIdentityVerificationNotificationEvent', {
          action: 'viewed',
          object: 'banner',
        });
      }
      return shouldShow;
    },
  ),

  // For user presence we want to display the last_request_at attribute closer to the avatar so we adjust the order here
  adjustedVisibleDisplayedAttributes: computed(
    'visibleDisplayedAttributes.[]',
    'segment.hasAnonymousPredicate',
    function () {
      let attributes = this.visibleDisplayedAttributes;
      let newIndex = 0;
      if (this.lastRequestAtAttributeIsVisible) {
        let lastRequestAtIndex = attributes.indexOf(this.lastRequestAtAttribute);
        if (this.userIdAttribute) {
          newIndex = attributes.indexOf(this.userIdAttribute) + 1;
        } else if (this.phoneAttribute) {
          newIndex = attributes.indexOf(this.phoneAttribute) + 1;
        } else if (this.emailAttribute) {
          newIndex = attributes.indexOf(this.emailAttribute) + 1;
        }
        attributes.splice(newIndex, 0, attributes.splice(lastRequestAtIndex, 1)[0]);

        if (!attributes.isAny('userAttributeSetting.isSorted')) {
          this.set('lastRequestAtAttribute.userAttributeSetting.sort_by', 'desc');
        }
      }
      return this.attributesHidingForContacts(
        this.get('segment.hasAnonymousPredicate'),
        attributes,
      );
    },
  ),

  init() {
    this._super(...arguments);
    if (!this.intercomCallService.isWorkspacePhoneNumbersLoaded) {
      this.intercomCallService.getWorkspacePhoneNumbers();
    }
  },

  attributesHidingForContacts(isAnonymous, attributes) {
    if (!isAnonymous) {
      return attributes;
    }
    return attributes.filter(function (attr) {
      return !attr.attribute.get('isHiddenForContact');
    });
  },

  viewMode: 'table',
  mapUsersLoadedCount: 0,
  totalMapUsersCount: undefined,
  mapUsersHaveLoaded: false,
  numberOfUserCountries: 0,

  showingTable: equal('viewMode', 'table'),

  personTypeName: alias('segment.personTypeName'),
  userCount: readOnly('app.userCount'),
  anonymousCount: readOnly('app.anonymousCount'),

  isMessengerInstalled: alias('app.hasAnyInstalledAtDate'),

  personTypeIsLead: equal('segment.personTypeName', 'lead'),

  peopleCount: computed(
    'segment.hasAnonymousPredicate',
    'segment.hasUserPredicate',
    'anonymousCount',
    'userCount',
    function () {
      if (this.get('segment.hasAnonymousPredicate')) {
        return this.anonymousCount;
      }
      if (this.get('segment.hasUserPredicate')) {
        return this.userCount;
      }
      return this.get('app.peopleCount');
    },
  ),

  loadPage(page) {
    if (page > 2) {
      this.intercomEventService.trackEvent('user-list-loaded-page', { page });
    }
    return this.executeSearch(User, 'users', { page });
  },

  isShowingAll: computed('totalCount', 'peopleCount', function () {
    return this.totalCount >= this.peopleCount;
  }),

  pagingStrategy: 'height',

  tagTypeLabel: alias('personTypeName'),
  tagEndpoint: '/ember/users/bulk_tag.json',

  actions: {
    showUserOrCompany(user) {
      this.router.transitionTo('apps.app.users.user', user.id);
    },
    openMessageModal() {
      this.trigger('openMessageModal');
    },
    onViewModeChange(newViewMode) {
      if (newViewMode === 'map') {
        this.intercomEventService.trackEvent('viewed-map');
      }

      this.set('viewMode', newViewMode);
    },

    showCreateUserModal() {
      this.set('showUserCreateModal', true);
    },

    showCreateLeadModal() {
      this.set('showLeadCreateModal', true);
    },

    import() {
      this.modalService.openModal('modal/import-modal');
    },

    redirectToIdentityVerification() {
      this.send('trackIdentityVerificationNotificationEvent', {
        action: 'clicked',
        object: 'banner',
      });
      this.router.transitionTo('apps.app.settings.workspace.security', {
        queryParams: { tab: 'enforce-identity' },
      });
    },

    trackIdentityVerificationNotificationEvent(data) {
      this.intercomEventService.trackEvent(`identity-verification-notification-${data.action}`, {
        action: data.action,
        object: data.object,
        place: 'user_list',
        owner: 'growth',
      });
    },
  },
});
