/* import __COLOCATED_TEMPLATE__ from './crm-lead-profile-component.hbs'; */
/* RESPONSIBLE TEAM: team-data-foundations */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { equal, gt, readOnly, filterBy, and, or, notEmpty } from '@ember/object/computed';
import { computed } from '@ember/object';
import Component from '@ember/component';
import { task } from 'ember-concurrency';
import { ternary, valueOrDefault } from '@intercom/pulse/lib/computed-properties';
import DidChangeAttrs from 'ember-did-change-attrs';
import { inject as service } from '@ember/service';
import { isBlank, isEmpty } from '@ember/utils';
import { capitalize } from '@ember/string';

const CRM_PROFILE_NAME_MAPPINGS = {
  salesforce: 'salesforce',
  hubspot: 'hubspot',
  marketo_enrichment: 'marketo',
  pipedrive: 'pipedrive',
};

export default Component.extend(DidChangeAttrs, {
  intercomEventService: service(),
  notificationsService: service(),
  logService: service(),
  store: service(),
  router: service(),
  appService: service(),
  app: readOnly('appService.app'),
  notificationDuration: 10000,
  eventTrackingCreateAction: 'created',
  eventTrackingVisitAction: 'clicked',
  eventTrackingKey: computed('profileName', function () {
    return `${this.profileName}_lead`;
  }),
  profileName: computed('profileType', function () {
    return CRM_PROFILE_NAME_MAPPINGS[this.profileType];
  }),
  camelizedProfileName: computed('profileName', function () {
    if (this.profileName === 'hubspot') {
      return 'HubSpot'; // we don't want to just capitalize "hubspot". We want to capitalize both the H and the S.
    }
    return capitalize(this.profileName);
  }),
  isHubspotProfileName: equal('profileName', 'hubspot'),
  isPipedriveProfileName: equal('profileName', 'pipedrive'),
  leadOrDeal: ternary('isPipedriveProfileName', 'deal', 'lead'),
  leadEmail: readOnly('user.email'),
  leadMatchCount: valueOrDefault('profile.recordCount', 'Multiple'),
  leadText: computed('profile.name', 'user.email', function () {
    let name = this.get('profile.name');
    return isBlank(name) ? this.get('user.email') : name;
  }),
  isMultipleLeads: filterBy('profile.reasons', 'type', 'MULTIPLE_LEADS'),
  identityMappingValueInvalidReasons: filterBy('profile.reasons', 'type', 'NO_ID_MAPPING_VALUE'),
  hasIdentityMappingValueInvalidReasons: notEmpty('identityMappingValueInvalidReasons'),
  extraDealCount: computed('profile.dealCount', function () {
    return this.get('profile.dealCount') - 1;
  }),
  hasExtraDeals: gt('extraDealCount', 0),
  didChangeAttrsConfig: {
    attrs: ['profileChangedAttribute'],
  },
  // We should allow customers to create HubSpot contacts through the External profile section only in user profiles
  // https://github.com/intercom/intercom/issues/156674

  shouldInformUserOfHubspotApp: and('isHubspotProfileName', 'conversation'),
  invalidIdentityMappingValue: or('user.hasNoEmail', 'hasIdentityMappingValueInvalidReasons'),
  invalidIdentityMappingMessage: computed(
    'camelizedProfileName',
    'hasIdentityMappingValueInvalidReasons',
    function () {
      if (this.hasIdentityMappingValueInvalidReasons) {
        return `You cannot create a lead because of how the ${this.camelizedProfileName} app is configured. Please contact your admin.`;
      }
      return `The lead must have an email before being created in ${this.camelizedProfileName}`;
    },
  ),
  profileObjectType: computed('isHubspotProfileName', 'isPipedriveProfileName', function () {
    if (this.isHubspotProfileName) {
      return 'contact';
    } else if (this.isPipedriveProfileName) {
      return 'deal';
    } else {
      return 'lead';
    }
  }),
  didChangeAttrs(changes) {
    this._super(...arguments);
    if (changes.profileChangedAttribute) {
      this.fetchProfile.perform();
    }
  },
  didInsertElement() {
    this._super(...arguments);
    this.fetchProfile.perform();
  },

  redirectToSalesforceSettings() {
    this.router.transitionTo('apps.app.appstore', {
      queryParams: { app_package_code: 'salesforce-by-intercom' },
    });
  },

  fetchProfile: task(function* () {
    try {
      let crmProfiles = yield this.store.query('crm-profile', {
        user_id: this.get('user.id'),
        profile_type: this.profileType,
      });
      let crmProfile = crmProfiles.get('firstObject');
      this.set('profile', crmProfile);
    } catch (e) {
      this.set('profile', {});
    }
  }).drop(),
  createProfile: task(function* () {
    if (this.shouldInformUserOfHubspotApp) {
      return;
    }

    if (isEmpty(this.get('user.email'))) {
      return;
    }

    let profileName = this.camelizedProfileName;
    let leadOrDeal = this.leadOrDeal;
    try {
      let conversationId = this.get('conversation.id');
      let record =
        conversationId && this.isPipedriveProfileName
          ? {
              userId: this.get('user.id'),
              profileType: this.profileType,
              conversationId,
            }
          : {
              userId: this.get('user.id'),
              profileType: this.profileType,
            };

      let crmProfile = yield this.store.createRecord('crm-profile', record).save();

      this.trackEvent(this.eventTrackingKey, this.eventTrackingCreateAction, true);
      if (crmProfile.get('url')) {
        this.set('profile', crmProfile);
        this.notificationsService.notifyConfirmation(
          `${profileName}` + ` ${leadOrDeal} has been ` + `created with ${this.get('user.email')}`,
          this.notificationDuration,
        );
      } else {
        this.set('profile', {});
        this.notificationsService.notifyWarning(
          `${profileName} ${leadOrDeal} has been created ` +
            `with ${this.get('user.email')}, but we couldn't fetch the ` +
            `${profileName} lead or contact url.`,
          this.notificationDuration,
        );
      }
    } catch (e) {
      this.set('profile', {});
      this.trackEvent(this.eventTrackingKey, this.eventTrackingCreateAction, false);
      this.notifyError(e, profileName, leadOrDeal);
    }
  }).drop(),

  notifyError(e, profileName, leadOrDeal) {
    if (e.status === 'invalid_email') {
      this.notificationsService.notifyError(
        `We failed to create ${profileName} ${leadOrDeal} with invalid email ${this.get(
          'user.email',
        )}`,
        this.notificationDuration,
      );
    } else if (this.profileName === 'salesforce') {
      this.notificationsService.notifyErrorWithButton(
        `We failed to create ${profileName} ${leadOrDeal}`,
        {
          label: 'Learn more',
          action: this.redirectToSalesforceSettings.bind(this),
        },
        this.notificationDuration,
      );
    } else {
      this.logService.log(`CRM ${leadOrDeal} creation fail: ${e}`);
      this.notificationsService.notifyError(
        `We failed to create ${profileName} ${leadOrDeal} with ${this.get('user.email')}`,
        this.notificationDuration,
      );
    }
  },

  trackEvent(object, action, success) {
    this.intercomEventService.trackAnalyticsEvent({
      object,
      action,
      models: [this.conversation, this.user].filter(function (e) {
        return e !== undefined;
      }),
      success,
      failure_reason: '',
    });
  },
  actions: {
    trackExternalProfileLinkClick() {
      this.trackEvent(this.eventTrackingKey, this.eventTrackingVisitAction, true);
    },
  },
});
