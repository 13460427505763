/* import __COLOCATED_TEMPLATE__ from './wait-for-callback.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import type WaitForCallback from 'embercom/models/operator/visual-builder/step/wait-for-callback';

interface Arguments {
  step: WaitForCallback;
  readOnly: boolean;
  editorState: any;
}

export default class WaitForCallbackComponent extends Component<Arguments> {
  @service intl!: IntlService;

  get title() {
    return this.args.step.name
      ? this.intl.t('operator.workflows.visual-builder.wait-for-callback.title-with-name', {
          name: this.args.step.name,
          htmlSafe: true,
        })
      : this.intl.t('operator.workflows.visual-builder.wait-for-callback.title', {
          htmlSafe: true,
        });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::NodeItems::Steps::WaitForCallback': typeof WaitForCallbackComponent;
    'workflows/graph-editor/node-items/steps/wait-for-callback': typeof WaitForCallbackComponent;
  }
}
