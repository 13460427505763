/* import __COLOCATED_TEMPLATE__ from './data-field.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { Type } from 'embercom/models/operator/visual-builder/attribute-descriptor';
import { tracked } from '@glimmer/tracking';
import { hasFeature } from 'embercom/helpers/has-feature';

export default class DataField extends Component {
  @service appService;
  @service attributeService;
  @service intl;
  @tracked showSelectedLocalVariableModal = false;
  @tracked showChoiceLocalVariableModal = false;

  get showNewAttributeButton() {
    return this.args.step.stepConfig.allowAttributeCreation;
  }

  get canShowWorkflowTemporaryAttributeCreation() {
    return (
      hasFeature('team-actions-workflow-temporary-attribute-creation', this.appService) &&
      this.args.step.stepConfig.allowLocalVariableCreation
    );
  }

  get selectedAttribute() {
    return this.attributeGroupList
      .map((group) => group.attributes)
      .flat()
      .find((attribute) => attribute.identifier === this.args.step.attributeIdentifier);
  }

  get attributeGroupList() {
    return this.args.editorConfiguration.collectableAttributesGroupList.map((group) => {
      return {
        heading: group.heading,
        attributes: group.attributes.filter(
          (attribute) => !this.excludedAttributeIdentifiers.includes(attribute.identifier),
        ),
      };
    });
  }

  get excludedAttributeIdentifiers() {
    if (!this.choiceAttributeDescriptor) {
      return [];
    }

    return [this.choiceAttributeDescriptor.identifier].concat(
      this.choiceAttributeDescriptor.allDescendantDescriptorIdentifiers,
    );
  }

  get collectingArrayItem() {
    if (!this.args.step.attributeCollectionSelectorParams) {
      return false;
    }

    return (
      this.args.step.selectionAttributeDescriptor &&
      this.args.step.choiceAttributeDescriptor &&
      this.args.step.displayAttributeDescriptor
    );
  }

  get choiceAttributeDescriptor() {
    return this.args.step.choiceAttributeDescriptor;
  }

  get displayAttributeIdentifiersItems() {
    return this.availableListItems(this.args.step.selectionAttributeDescriptor.arrayItemDescriptor);
  }

  get displayAttributeIdentifier() {
    return this.args.step.attributeCollectionSelectorParams?.displayAttributeIdentifier;
  }

  @action selectDisplayAttributeIdentifier(value) {
    this.args.step.attributeCollectionSelectorParams.displayAttributeIdentifier = value;
  }

  @action selectAttribute(attribute) {
    let attributeLabel = attribute.get('name');
    let attributeIdentifier = attribute.get('identifier');
    let attributeDataType = attribute.get('type');
    let isIdentifyingAttribute =
      this.attributeService.userIdentifyingAndNonEditableAttributes.includes(attributeIdentifier);

    if (attributeDataType === Type.array) {
      let arrayItemAttributeDescriptor = attribute.arrayItemDescriptor;
      let newAttribute = this.createArrayItemLocalVariableCopy(arrayItemAttributeDescriptor);
      this.args.step.attributeCollectionSelectorParams = {
        selectionAttributeIdentifier: attributeIdentifier,
        choiceAttributeIdentifier: newAttribute.get('identifier'),
        displayAttributeIdentifier: this.availableListItems(arrayItemAttributeDescriptor)
          .firstObject?.value,
        showNoneOfTheAboveOption: true,
      };
    } else {
      if (this.choiceAttributeDescriptor) {
        this.args.editorState.deleteLocalVariable(this.choiceAttributeDescriptor);
      }
      this.args.step.attributeCollectionSelectorParams = null;
    }

    this.args.step.attributeCollectionOverwritable = !isIdentifyingAttribute;
    this.args.step.attributeCollectionDisabled = isIdentifyingAttribute;
    this.args.step.attributeIdentifier = attributeIdentifier;
    this.args.step.attributeLabel = attributeLabel;
    this.args.step.attributeDataType = attributeDataType;
  }

  @action createTemporaryAttribute(name, type) {
    return this.args.editorState.createLocalVariable(name, type);
  }

  @action deleteTemporaryAttribute(attribute) {
    if (attribute.identifier === this.selectedAttribute.identifier) {
      this.args.step.attributeIdentifier = null;
      this.args.step.attributeCollectionSelectorParams = null;
    }
    this.args.editorState.deleteLocalVariable(attribute);
  }

  availableListItems(attribute) {
    let possibleTypes = [
      Type.string,
      Type.integer,
      Type.float,
      Type.date,
      Type.boolean,
      Type.workflow_attribute_descriptor_list,
    ];
    return attribute.childDescriptors
      .toArray()
      .filter((descriptor) => possibleTypes.includes(descriptor.type))
      .map((descriptor) => {
        return {
          text: descriptor.sourceObject.path,
          value: descriptor.identifier,
        };
      });
  }

  createArrayItemLocalVariableCopy(arrayItemAttributeDescriptor) {
    let name = this.intl.t(
      'operator.workflows.visual-builder.attribute-collector.choice-attribute-default-name',
      {
        attribute_name: arrayItemAttributeDescriptor.name,
      },
    );
    return this.args.editorState.copyLocalVariable(arrayItemAttributeDescriptor, name);
  }
}
