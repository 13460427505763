/* import __COLOCATED_TEMPLATE__ from './first-group-preview.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import type Group from 'embercom/models/operator/visual-builder/group';
import type Step from 'embercom/models/operator/visual-builder/step';
import { get } from '@ember/object';

type PreviewStep =
  | (Step & {
      serializedBlocks?: $TSFixMe[];
      attributeIdentifier?: string;
    })
  | undefined;

interface Signature {
  Args: {
    group: Group;
  };
}

export default class FirstGroupPreview extends Component<Signature> {
  get previewSteps() {
    let steps: PreviewStep[] = [];
    let firstGroupSteps = this.args.group.steps;
    for (let i = 0; i < get(firstGroupSteps, 'length'); i++) {
      let step = firstGroupSteps.objectAt(i);
      if (step) {
        steps.push(step);

        if (this._isFreeInput(step)) {
          break;
        }
      }
    }
    return steps;
  }

  get showOperatorPart() {
    return this.previewSteps.some(
      (step) =>
        (step as { serializedBlocks?: $TSFixMe[] }).serializedBlocks != null ||
        this._isAttributeCollector(step),
    );
  }

  get groupEndStep() {
    return this.previewSteps.lastObject?.isGroupEnding ? this.previewSteps.lastObject : undefined;
  }

  get showComposer() {
    return (
      this._isFreeInput(this.previewSteps.lastObject) ||
      this._isHandoverToResolutionBot(this.previewSteps.lastObject)
    );
  }

  get replyButtonLabels(): string[] | undefined {
    return this.groupEndStep?.outwardConnectionPoints?.toArray().map((button) => button.label);
  }

  _isAttributeCollector(step: PreviewStep) {
    return step?.type === 'operator/visual-builder/step/attribute-collector';
  }

  _isFreeInput(step: PreviewStep) {
    return step?.type === 'operator/visual-builder/step/free-input';
  }

  _isHandoverToResolutionBot(step: PreviewStep) {
    return step?.type === 'operator/visual-builder/step/answer-bot';
  }
}
declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::NodeItems::Steps::TriggerWorkflow::FirstGroupPreview': typeof FirstGroupPreview;
    'workflows/graph-editor/node-items/steps/trigger-workflow/first-group-preview': typeof FirstGroupPreview;
  }
}
