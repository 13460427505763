/* import __COLOCATED_TEMPLATE__ from './vertical-segment-list.hbs'; */
/* RESPONSIBLE TEAM: team-data-foundations */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { computed } from '@ember/object';
import { readOnly, setDiff, sort, notEmpty } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import { segmentSortFunction } from 'embercom/lib/segments/sorting';
import { ternary } from '@intercom/pulse/lib/computed-properties';

export default Component.extend({
  appService: service(),
  app: readOnly('appService.app'),
  admin: readOnly('app.currentAdmin'),
  segmentSettingsRoute: ternary(
    'isCompany',
    'apps.app.account.visibility.company-segments',
    'apps.app.account.visibility.user-segments',
  ),
  visibleSegments: computed('segments.[]', 'admin.visible_segment_ids.[]', function () {
    let visibleSegmentIds = this.get('admin.visible_segment_ids');
    if (!visibleSegmentIds) {
      return [];
    }
    return this.segments.filter(function (segment) {
      if (!segment.get('is_editable')) {
        return true;
      }
      return visibleSegmentIds.includes(segment.get('id').toString());
    });
  }),
  hiddenSegments: setDiff('segments', 'visibleSegments'),
  sortedHiddenSegments: sort('hiddenSegments', segmentSortFunction),
  hasHiddenSegments: notEmpty('hiddenSegments'),
  sortedSegmentsToDisplay: sort('visibleSegments', segmentSortFunction),
  listIsCollapsed: true,
});
