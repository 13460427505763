/* import __COLOCATED_TEMPLATE__ from './attribute-component.hbs'; */
/* RESPONSIBLE TEAM: team-data-foundations */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { computed } from '@ember/object';
import { reads, match, and } from '@ember/object/computed';
import Component from '@ember/component';
import ProfileAttribute from 'embercom/components/mixins/profile-attribute';
import { capitalize } from '@ember/string';

export default Component.extend(ProfileAttribute, {
  classNames: ['kv__pair', 'u__cf', 'o__in-list'],
  attributeBindings: ['dataKey:data-key'],
  dataKey: reads('attribute.identifier'),
  valueTooltipText: '',
  inInbox: false,
  hasCompanyPrefix: match('attribute.name', /^Company */),
  onCompanyWithPrefix: and('hasCompanyPrefix', 'onCompany'),
  formattedName: computed('onCompanyWithPrefix', 'attribute.name', function () {
    if (this.onCompanyWithPrefix === true) {
      return capitalize(this.get('attribute.name').replace('Company ', ''));
    }
    return this.get('attribute.name');
  }),
});
