/* import __COLOCATED_TEMPLATE__ from './attribute-mapping.hbs'; */
/* RESPONSIBLE TEAM: team-actions */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { isEmpty } from '@ember/utils';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import {
  CUSTOM_ACTION_CONTEXT_MAPPING_PEOPLE,
  CUSTOM_ACTION_CONTEXT_MAPPING_CONVERSATION,
  CUSTOM_ACTION_CONTEXT_MAPPING_IDENTIFIERS,
} from 'embercom/models/workflow-connector/constants/context-mapping-types';
import {
  USER_OBJECT_TYPE_IDENTIFIER,
  CONVERSATION_OBJECT_TYPE_IDENTIFIER,
} from 'embercom/models/custom-objects/constants/object-types';
import { capitalize } from '@ember/string';

export default class AttributeMapping extends Component {
  @service store;
  @service intl;
  @service appService;
  @tracked attributeMapping = this.args.attributeMapping;

  get app() {
    return this.appService.app;
  }

  get selectedResponseAttributePath() {
    // prevent dropdown component from throwing exceptions
    if (this.selectedApiAttributeMissing) {
      return undefined;
    }

    return this.attributeMapping.responseAttributePath;
  }

  get selectedApiAttributeMissing() {
    // differentiate between new attribute mapping and existing that doesn't have a matching attribute
    if (isEmpty(this.attributeMapping.responseAttributePath)) {
      return false;
    }

    // if attribute has been set to map from context, we don't need this check
    if (this.attributeMapping.mappingType === 'context_mapping') {
      return false;
    }

    return !this.attributeMapping.apiMappableAttributePresent;
  }

  get apiAttributeDropdownLabel() {
    if (this.selectedApiAttributeMissing) {
      return this.attributeMapping.responseAttributePath;
    }

    return this.intl.t(
      'workflow-connector.builder.body.response.mapping.dropdown.select-api-attribute',
    );
  }

  get hasErrors() {
    return this.showApiAttributeDropdownError || this.showIntercomAttributeDropdownError;
  }

  get showApiAttributeDropdownError() {
    return this.selectedApiAttributeMissing || this.attributeMapping.dataTypesIncompatible;
  }

  get showIntercomAttributeDropdownError() {
    // TODO intercom attribute missing
    return this.attributeMapping.dataTypesIncompatible;
  }

  get apiAttributeMappingOptions() {
    if (isEmpty(this.attributeMapping.objectMapping)) {
      return [];
    }

    let apiAttributeMappingOptions = this.attributeMapping.objectMapping.apiMappableAttributes.map(
      (apiMappableAttribute) => {
        return {
          text: apiMappableAttribute.name,
          value: apiMappableAttribute.fullPath,
          count: capitalize(apiMappableAttribute.dataType),
          tooltipText: `${apiMappableAttribute.fullPath} = ${apiMappableAttribute.exampleValue}`,
        };
      },
    );

    let contextMappingOptions = [];
    if (
      this._isCustomObject(this.attributeMapping.objectMapping.intercomObjectType) &&
      this.attributeMapping.intercomAttributeIdentifier !== undefined
    ) {
      let intercomObjectAttribute = this.store.peekRecord(
        'objects/attribute-descriptor',
        this.attributeMapping.intercomAttributeIdentifier,
      );

      if (this._dataTypeIsRelationship(intercomObjectAttribute)) {
        contextMappingOptions = this._contextMappingOptions(intercomObjectAttribute);
      }
    }

    return contextMappingOptions.length > 0 ? contextMappingOptions : apiAttributeMappingOptions;
  }

  _isCustomObject(intercomObjectType) {
    return ![CONVERSATION_OBJECT_TYPE_IDENTIFIER, USER_OBJECT_TYPE_IDENTIFIER].includes(
      intercomObjectType,
    );
  }

  _dataTypeIsRelationship(intercomObjectAttribute) {
    return (
      intercomObjectAttribute !== undefined &&
      intercomObjectAttribute !== null &&
      intercomObjectAttribute.dataType === 'relationship'
    );
  }

  _contextMappingOptions(intercomObjectAttribute) {
    let contextMappingOptions = [];
    let referencedObjectTypeIdentifier =
      intercomObjectAttribute.reference?.referencedObjectTypeIdentifier;

    if (referencedObjectTypeIdentifier === USER_OBJECT_TYPE_IDENTIFIER) {
      contextMappingOptions.push({
        text: this.intl.t(
          'workflow-connector.builder.body.response.mapping.context-mapping.people',
        ),
        value: CUSTOM_ACTION_CONTEXT_MAPPING_PEOPLE,
        count: this.intl.t(
          'workflow-connector.builder.body.response.mapping.context-mapping.from-context-lower',
        ),
        tooltipText: this.intl.t(
          'workflow-connector.builder.body.response.mapping.context-mapping.map-to-appropriate-person',
        ),
      });
    } else if (referencedObjectTypeIdentifier === CONVERSATION_OBJECT_TYPE_IDENTIFIER) {
      contextMappingOptions.push({
        text: this.intl.t(
          'workflow-connector.builder.body.response.mapping.context-mapping.conversation',
        ),
        value: CUSTOM_ACTION_CONTEXT_MAPPING_CONVERSATION,
        count: this.intl.t(
          'workflow-connector.builder.body.response.mapping.context-mapping.from-context-lower',
        ),
        tooltipText: this.intl.t(
          'workflow-connector.builder.body.response.mapping.context-mapping.map-to-appropriate-conversation',
        ),
      });
    }

    return contextMappingOptions;
  }

  @action
  setIntercomAttributeIdentifier(newIntercomAttributeIdentifier) {
    this.attributeMapping.intercomAttributeIdentifier = newIntercomAttributeIdentifier;
    this.attributeMapping.responseAttributePath = undefined;
  }

  @action
  setResponseAttributePath(newAttributeResponsePath) {
    this.attributeMapping.responseAttributePath = newAttributeResponsePath;
    if (CUSTOM_ACTION_CONTEXT_MAPPING_IDENTIFIERS.includes(newAttributeResponsePath)) {
      this.attributeMapping.mappingType = 'context_mapping';
    } else {
      this.attributeMapping.mappingType = 'primitive_mapping';
    }
  }
}
