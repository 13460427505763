/* import __COLOCATED_TEMPLATE__ from './preview-wrapper.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency-decorators';
import type Store from '@ember-data/store';
import { type TaskGenerator } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';

interface Signature {
  Args: {
    workflowId: string;
  };
}

export default class PreviewWrapper extends Component<Signature> {
  @service declare store: Store;

  @tracked workflow: $TSFixMe; // models/operator/custom-bot

  get workflowLoaded() {
    return this.workflow && !taskFor(this.fetchWorkflow).isRunning;
  }

  get path() {
    return this.workflow?.visualBuilderObject.groups.firstObject;
  }

  @task({ restartable: true })
  *fetchWorkflow(id: string): TaskGenerator<void> {
    let workflow = yield this.store.findRecord('operator/custom-bot', id);
    this.workflow = workflow;
  }
}
declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::NodeItems::Steps::TriggerWorkflow::PreviewWrapper': typeof PreviewWrapper;
    'workflows/graph-editor/node-items/steps/trigger-workflow/preview-wrapper': typeof PreviewWrapper;
  }
}
