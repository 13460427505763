/* import __COLOCATED_TEMPLATE__ from './owner-component.hbs'; */
/* RESPONSIBLE TEAM: team-data-foundations */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { computed } from '@ember/object';
import { readOnly, none, filterBy, equal } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import { findBy, findByProperty, ternary } from '@intercom/pulse/lib/computed-properties';
import AttributeUpdater from 'embercom/components/mixins/people/attribute-updater';
import defaultTo from '@intercom/pulse/lib/default-to';

export default Component.extend(AttributeUpdater, {
  intercomEventService: service(),
  intl: service(),
  appService: service(),
  attributeService: service(),
  isInDetails: defaultTo(false),
  classNameBindings: ['ownerClass'],
  attributeBindings: ['data-test-account-owner'],
  'data-test-account-owner': true,
  app: readOnly('appService.app'),
  emptyOwnerId: none('person.owner_id'),
  attribute: findBy('attributeService.attributes', 'identifier', 'owner_id'),
  disabled: false,
  isEditing: defaultTo(false),
  teammates: filterBy('assignees', 'isTeam', false),
  owner: findByProperty('app.admins', 'id', 'ownerId'),
  ownerSource: readOnly('person.metadata.data_sources.owner_id.source'),
  displayedInProfileHeader: false,
  permissonToEdit: readOnly('app.currentAdmin.currentAppPermissions.can_reassign_conversations'),
  salesforceAssignedOwner: equal('ownerSource', 8),
  salesforceAssignedMessage: ternary(
    'emptyOwnerId',
    'inbox.conversation-details-sidebar.owner-component.not-match-with-teammates',
    'inbox.conversation-details-sidebar.owner-component.owner-assigned',
  ),
  manualAssignmentMessage: ternary(
    'isEditable',
    'inbox.conversation-details-sidebar.owner-component.new-owner',
    'inbox.conversation-details-sidebar.owner-component.ask-for-permission',
  ),
  isEditable: computed('salesforceAssignedOwner', 'adminCanEdit', function () {
    return (
      this.permissonToEdit && (!this.salesforceAssignedOwner || !this.app.hasSalesforceIntegration)
    );
  }),
  ownerClass: ternary(
    'isInDetails',
    'flex-none flex flex-row items-center justify-start u__mb__5 attribute__fit-content',
    '',
  ),
  ownerId: computed('emptyOwnerId', 'person.owner_id', function () {
    if (this.emptyOwnerId) {
      return null;
    }
    return this.get('person.owner_id').toString();
  }),
  accountOwnerTooltip: computed(
    'intl.locale',
    'salesforceAssignedOwner',
    'isEditable',
    function () {
      if (this.salesforceAssignedOwner) {
        return this.intl.t(this.salesforceAssignedMessage);
      }
      return this.intl.t(this.manualAssignmentMessage);
    },
  ),
  actions: {
    updateOwnerId(value) {
      if (value === undefined) {
        value = null;
      }
      if (value !== this.currentAttributeValue) {
        this._performUpdateTask('updateAttributeValue', value);
        this.set('person.owner_id', value);
      }
      let action = value === null ? 'remove' : 'add';
      this.intercomEventService.trackAnalyticsEvent({
        action,
        object: 'owner',
        place: 'inbox',
      });
    },
    beginEditing(e) {
      if (this.isEditable) {
        this.set('isEditing', true);
        e.stopPropagation();
      }
    },
    endEditing() {
      this.set('isEditing', false);
    },
  },
});
