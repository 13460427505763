/* import __COLOCATED_TEMPLATE__ from './user-card-attribute-component.hbs'; */
/* RESPONSIBLE TEAM: team-data-foundations */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-components */
import Component from '@ember/component';
import ProfileAttribute from 'embercom/components/mixins/profile-attribute';

export default Component.extend(ProfileAttribute);
