/* import __COLOCATED_TEMPLATE__ from './response.hbs'; */
/* RESPONSIBLE TEAM: team-actions */
import Component from '@glimmer/component';
import type Action from 'embercom/models/workflow-connector/action';
import { action } from '@ember/object';

interface Args {
  workflowAction: Action;
  nextSection: (sectionId: string) => void;
}

interface Signature {
  Args: Args;
}

// eslint-disable-next-line ember/no-empty-glimmer-component-classes
export default class Response extends Component<Signature> {
  @action
  previousSection() {
    this.args.nextSection('testing_section');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'WorkflowConnector::Builder::Body::Sections::Response': typeof Response;
    'workflow-connector/builder/body/sections/response': typeof Response;
  }
}
