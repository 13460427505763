/* import __COLOCATED_TEMPLATE__ from './handoff-to-url.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import type HandoffToUrlType from 'embercom/models/operator/visual-builder/step/handoff-to-url';
import type EditorState from 'embercom/objects/workflows/graph-editor/editor-state';
import { inject as service } from '@ember/service';
import type CustomIntlService from 'embercom/services/intl';

export interface HandoffToUrlArgs {
  step: HandoffToUrlType;
  editorState: EditorState;
  readOnly: boolean;
}

interface Signature {
  Element: HTMLDivElement;
  Args: HandoffToUrlArgs;
}

export default class HandoffToUrl extends Component<Signature> {
  @service declare intl: CustomIntlService;

  @action
  updateHandoffUrl(event: Event) {
    this.args.step.handoffUrl = (event.target as HTMLInputElement).value;
  }

  get title() {
    if (this.args.step.handoffUrl) {
      return this.intl.t('operator.workflows.visual-builder.handoff-to-url.title', {
        url: this.args.step.handoffUrl,
        htmlSafe: true,
      });
    } else {
      return this.intl.t('operator.workflows.visual-builder.handoff-to-url.empty', {
        htmlSafe: true,
      });
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::NodeItems::Steps::HandoffToUrl': typeof HandoffToUrl;
    'workflows/graph-editor/node-items/steps/handoff-to-url': typeof HandoffToUrl;
  }
}
