/* import __COLOCATED_TEMPLATE__ from './custom-action-tab-separator.hbs'; */
/* RESPONSIBLE TEAM: team-actions */

import templateOnlyComponent from '@ember/component/template-only';

const CustomActionTabSeparator = templateOnlyComponent();
export default CustomActionTabSeparator;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'WorkflowConnector::CustomActionTabSeparator': typeof CustomActionTabSeparator;
    'workflow-connector/custom-action-tab-separator': typeof CustomActionTabSeparator;
  }
}
