/* import __COLOCATED_TEMPLATE__ from './rules-editor.hbs'; */
/* RESPONSIBLE TEAM: team-data-foundations */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
import Component from '@ember/component';
import { assert } from '@ember/debug';
import { computed } from '@ember/object';
import { and, not, readOnly, union } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { copy } from 'ember-copy';

const OR_FILTER_LIMIT = 10;
const EXTENDED_OR_FILTER_LIMIT = 20;

export default Component.extend({
  tagName: '',
  intl: service(),
  connectionSwitcherHovered: false,
  segmentViewCollapsedService: service(),
  collapsed: readOnly('segmentViewCollapsedService.collapsed'),
  notCollapsed: not('collapsed'),
  attributeDropdownFilterPlaceholder: computed(
    'intl.locale',
    'showUserData',
    'app.companiesActive',
    'predicateGroup.userType',
    function () {
      let result = this.intl.t('users-and-companies.rules-editor.search-data');
      if (this.showUserData && this.app.companiesActive) {
        result = this.intl.t('users-and-companies.rules-editor.search-people-company');
      } else if (this.showUserData) {
        result = this.intl.t('users-and-companies.rules-editor.search-people');
      } else if (this.app.companiesActive) {
        result = this.intl.t('users-and-companies.rules-editor.search-company');
      }
      return result;
    },
  ),
  logicalSwitcherItems: [],
  numberOfFiltersToDisplayWhenCollapsed: 1,
  numberOfFiltersAtWhichToDisplayHideFilterButton: 5,
  intercomEventService: service(),
  showUserData: true,
  showCreateTagFromCsvAction: false,
  showCreateTagFromCsvModal: false,
  appService: service(),
  attributeService: service(),
  // eslint-disable-next-line @intercom/intercom/no-legacy-modal
  modalService: service(),
  permissionsService: service(),
  app: readOnly('appService.app'),
  init() {
    this._super(...arguments);
    this.set(
      'logicalSwitcherTooltip',
      this.intl.t('users-and-companies.rules-editor.logical-tooltip', {
        limit: this.orFilterLimit,
      }),
    );
    this.set(
      'addFilterTooltip',
      this.intl.t('users-and-companies.rules-editor.add-tooltip', {
        limit: this.orFilterLimit,
      }),
    );
    this.set('logicalSwitcherItems', [
      {
        text: this.intl.t('users-and-companies.rules-editor.and'),
        value: 'and',
      },
      {
        text: this.intl.t('users-and-companies.rules-editor.or'),
        value: 'or',
      },
    ]);
    this.set('newAttributeAction', {
      text: this.intl.t('users-and-companies.rules-editor.new-attribute'),
      icon: 'new',
      action: () => this.send('showNewDataModal'),
    });
    this.set('filterAudienceByCsvAction', {
      component: 'users-and-companies/rules-editor/create-tag-from-csv-action',
      componentAttrs: {
        place: this.showUserData ? 'contacts' : 'editor',
        isDisabled: this.disableFilterAudienceCsvAction,
      },
      action: () => this.openCsvModalAction(),
    });
    assert(
      `The predicateGroup must have a user predicate if showUserSwitcher is true. predicateGroup: ${JSON.stringify(
        this.predicateGroup.serialize(),
      )}`,
      !this.showUserSwitcher || (this.showUserSwitcher && this.predicateGroup.userPredicate),
    );
  },
  canUseCdaCreator: and('app.currentAdmin.hasProductAccessForCurrentApp', 'showUserData'),
  // Note: @pat - App attributes are loaded on page load but if we want to dynamically track updates to these models over the lifetime of the app and update user/companyAttributes, we should track specific properties on the models instead.
  userAttributes: readOnly('attributeService.filterableUserAttributesMaybeWithSegmentsAndTags'),
  companyAttributes: readOnly(
    'attributeService.filterableCompanyAttributesMaybeWithPlansSegmentsAndTags',
  ),
  visibilitySettings: readOnly('attributeService.attributeSettings'),
  priorityUserAttributes: computed('userAttributes.[]', 'visibilitySettings.[]', function () {
    return this.userAttributes.filter(
      (a) => a.requiredFilter || this.visibilitySettings.findBy('attribute', a.identifier),
    );
  }),
  sortedUserAttributes: union('priorityUserAttributes', 'userAttributes'),
  canTagWithCsv: computed(function () {
    return this.permissionsService.currentAdminCan('can_manage_tags');
  }),
  showFilterAudienceByCsvOption: and('canTagWithCsv', 'showCreateTagFromCsvAction'),
  disableFilterAudienceCsvAction: computed('selectedUserTypes', function () {
    return Boolean(this.selectedUserTypes && this.selectedUserTypes.includes('visitor'));
  }),
  openCsvModalAction() {
    if (!this.disableFilterAudienceCsvAction) {
      this.set('showCreateTagFromCsvModal', true);
    }
  },
  footerActions: computed(
    'newAttributeAction',
    'selectedUserTypes',
    'filterAudienceByCsvAction',
    function () {
      let actions = [];
      let csvAction = this.filterAudienceByCsvAction;
      csvAction.componentAttrs.isDisabled = this.disableFilterAudienceCsvAction;

      if (this.canUseCdaCreator) {
        actions.push(this.newAttributeAction);
      }

      if (this.showFilterAudienceByCsvOption) {
        actions.push(csvAction);
      }
      return actions.length !== 0 ? actions : undefined;
    },
  ),
  attributeGroupList: computed(
    'intl.locale',
    'app.companiesActive',
    'predicateGroup.userType',
    'userAttributes.[]',
    'companyAttributes.[]',
    'showUserData',
    'invalidAttributes',
    function () {
      let groups = [];
      if (this.extraAttributeGroups) {
        groups.addObjects(this.extraAttributeGroups);
      }
      if (this.showUserData) {
        groups.push({
          heading: this.intl.t('users-and-companies.rules-editor.people-heading'),
          attributes: this.sortedUserAttributes,
        });
      }
      if (this.get('app.companiesActive')) {
        groups.push({
          heading: this.intl.t('users-and-companies.rules-editor.company-heading'),
          attributes: this.companyAttributes,
        });
      }
      if (this.invalidAttributes) {
        groups.forEach((group) => {
          group.attributes = group.attributes.filter(
            (attribute) => !this.invalidAttributes.includes(attribute.identifier),
          );
        });
      }
      return groups;
    },
  ),
  orFilterLimit: computed(function () {
    return this.app.canUseFeature('cdp_extend_or_filter_limit')
      ? EXTENDED_OR_FILTER_LIMIT
      : OR_FILTER_LIMIT;
  }),
  logicalSwitcherTooltip: '',
  addFilterTooltip: '',
  newAttributeAction: {},
  filterAudienceByCsvAction: {},
  actions: {
    showNewDataModal() {
      this.modalService.openModal('settings/modals/attribute-details', {
        allowArchival: false,
      });
    },
    toggleCollapsedState() {
      this.segmentViewCollapsedService.toggle();
    },
    proxiedOnUpdate(predicates, meta) {
      this.set('predicateGroup.predicates', copy(predicates));
      this.segmentViewCollapsedService.expand();
      let onUpdate = this.onUpdate;
      if (typeof onUpdate === 'function') {
        onUpdate(...arguments);
      }
    },
  },
});
