/* import __COLOCATED_TEMPLATE__ from './auto-close.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';
import type EditorState from 'embercom/objects/workflows/graph-editor/editor-state';
import type IntlService from 'ember-intl/services/intl';
import { inject as service } from '@ember/service';
import type BotPart from 'embercom/models/operator/bot-intro/part';

const MINUTE = 60;
const HOUR = 60 * MINUTE;
const DAY = 24 * HOUR;

interface Signature {
  Element: HTMLDivElement;
  Args: {
    accordion: $TSFixMe;
    step: $TSFixMe;
    isReadOnly: boolean;
    editorState: EditorState;
  };
}

export default class AutoClose extends Component<Signature> {
  @service declare intl: IntlService;

  @tracked days: number | string | undefined;
  @tracked hours: number | string | undefined;
  @tracked minutes: number | string | undefined;

  constructor(owner: unknown, args: Signature['Args']) {
    super(owner, args);

    this.days = Math.floor((this.args.step.inactivityTimer || 0) / DAY);
    this.hours = Math.floor(((this.args.step.inactivityTimer || 0) % DAY) / HOUR);
    this.minutes = Math.floor((((this.args.step.inactivityTimer || 0) % DAY) % HOUR) / MINUTE);
  }

  get durationInDaysHoursMinutes() {
    let formatKey = this._determineFormatKey();
    return this.intl.t(
      `operator.fin.setup.setup-and-go-live.fin-auto-close.duration-summary.${formatKey}`,
      {
        days: this._parseInt(this.days),
        hours: this._parseInt(this.hours),
        minutes: this._parseInt(this.minutes),
      },
    );
  }

  @action
  reorderParts(parts: [BotPart]) {
    this.args.step.autoCloseFollowUpMessages = parts;
  }

  @action
  removePart(part: BotPart) {
    this.args.step.autoCloseFollowUpMessages.removeObject(part);
  }

  @action
  addPart() {
    // @ts-ignore
    this.args.step.autoCloseFollowUpMessages.createFragment();
  }

  @action
  updateInactivityDuration(durationInSeconds: number) {
    this.args.step.inactivityTimer = durationInSeconds;
    this._setDaysHoursMinutes();
  }

  _setDaysHoursMinutes() {
    this.days = Math.floor(this.args.step.inactivityTimer / DAY);
    this.hours = Math.floor((this.args.step.inactivityTimer % DAY) / HOUR);
    this.minutes = Math.floor((this.args.step.inactivityTimer % DAY) % HOUR) / MINUTE;
  }

  _parseInt(value: string | number | undefined): number {
    if (value === undefined) {
      return 0;
    } else if (value === '') {
      return 0;
    } else if (typeof value === 'string') {
      return parseInt(value, 10);
    } else {
      return value;
    }
  }

  _determineFormatKey() {
    let formatKey = '';

    if (this._parseInt(this.days) > 0) {
      formatKey += 'd';
    }
    if (this._parseInt(this.hours) > 0) {
      formatKey += 'h';
    }
    if (this._parseInt(this.minutes) > 0) {
      formatKey += 'm';
    }

    if (formatKey === '') {
      formatKey = 'empty';
    }

    return formatKey;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::NodeItems::Steps::Fin::Sections::AutoClose': typeof AutoClose;
    'workflows/graph-editor/node-items/steps/fin/sections/auto-close': typeof AutoClose;
  }
}
