/* import __COLOCATED_TEMPLATE__ from './step-menu.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import {
  type EdgeParameters,
  type StepParameters,
} from 'embercom/objects/visual-builder/configuration/inserter-menu';
import type InserterMenuConfiguration from 'embercom/objects/visual-builder/configuration/inserter-menu';
import { type InserterMenuItemType } from 'embercom/objects/visual-builder/configuration/inserter-menu-items-map';
import type IntlService from 'embercom/services/intl';
import { type Placement } from '@popperjs/core';

interface Signature {
  Element: HTMLDivElement;
  Args: Args;
  Blocks: {
    default: [];
  };
}

interface Args {
  inserterMenuConfig: InserterMenuConfiguration;
  canInsertGroupEndingStep: boolean;
  extras?: EdgeParameters;
  stepMenuLocation?: string;
  shouldShowStepPaywalls?: boolean;
  placement?: Placement;
  showArrow?: boolean;
  onShow?: () => void;
  onHide?: () => void;
  fixedHeight?: boolean;
}

export default class StepMenu extends Component<Signature> {
  @service declare intl: IntlService;

  @action
  onSelectItem(stepParams: StepParameters, groupId?: string) {
    if (this.args.stepMenuLocation) {
      stepParams.analyticsData = {
        ...stepParams.analyticsData,
        step_menu_location: this.args.stepMenuLocation,
        ...(groupId ? { group_id: groupId } : {}),
      };
    }

    return this.args.inserterMenuConfig.onSelectItem(stepParams, this.args.extras);
  }

  @action
  addSingleItem() {
    let singleItem = this.stepInsertionOptions.find((option) => option.items.length === 1)
      ?.items[0];
    if (singleItem) {
      this.onSelectItem(singleItem.value as StepParameters);
    }
  }

  get stepInsertionOptions() {
    let isGroupEndingStepFilter = (step: InserterMenuItemType) =>
      typeof step.value !== 'string' && step.value.ModelClass?.isStepGroupEnding;

    let replaceWithStepsGroup = {
      heading: this.intl.t('operator.workflows.visual-builder.step-menu.replace-with'),
      isOpenByDefault: true, // accordion is open
      items: this.args.inserterMenuConfig.allAvailableStepGroups.flatMap((group) =>
        group.items.filter(isGroupEndingStepFilter),
      ),
    };

    return this.args.canInsertGroupEndingStep
      ? this.args.inserterMenuConfig.allAvailableStepGroups
      : [
          replaceWithStepsGroup,
          ...this.args.inserterMenuConfig.allAvailableStepGroups
            .map((group) => ({
              ...group,
              items: group.items.filter((item) => !isGroupEndingStepFilter(item)),
            }))
            .filter((group) => group.items.length),
        ];
  }

  get stepInsertionOptionsContainsSingleItem() {
    return this.stepInsertionOptions.reduce((acc, curr) => acc + curr.items.length, 0) === 1;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::StepMenu': typeof StepMenu;
    'workflows/graph-editor/default': typeof StepMenu;
  }
}
