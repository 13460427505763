/* import __COLOCATED_TEMPLATE__ from './settings.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import type IntlService from 'ember-intl/services/intl';
import type Store from '@ember-data/store';
import type Fin from 'embercom/models/operator/visual-builder/step/fin';
import { type AllChannels as Channel } from 'embercom/lib/operator/custom-bots/constants';
import { FIN_CSAT_COMPATIBLE_CHANNELS } from 'embercom/lib/operator/resolution-bot/constants';
import type EditorState from 'embercom/objects/workflows/graph-editor/editor-state';
import type FinConfiguration from 'embercom/objects/visual-builder/configuration/step/fin';

interface Arguments {
  step: Fin;
  isReadOnly: boolean;
  customAnswerCount: number;
  triageButtonLabel: string;
  thatHelpedButtonLabel: string;
  canUseAiAnswers: boolean;
  usesResolutionsWithCustomAnswers: boolean;
  canUseCustomAnswers: boolean;
  editorState: EditorState;
  stepConfig: FinConfiguration;
}

export default class Settings extends Component<Arguments> {
  @service declare intl: IntlService;
  @service declare store: Store;
  @service declare contentEditorService: any;
  @service declare appService: any;

  @tracked openSectionId = '';

  @tracked showCsatBlockAfterUpdate =
    this.args.step.csatBlockUpdateAfter !== undefined &&
    this.args.step.csatBlockUpdateAfter !== null;

  get oneTimeModeAvailable() {
    return (
      this.args.step.useCustomAnswers &&
      !this.args.step.useAiAnswers &&
      this.args.step.botMode !== 'bot_only'
    );
  }

  get botOnlyModeAvailable() {
    return this.args.step.botMode !== 'one_time';
  }

  get shouldShowChannelTypeHeaders() {
    if (!this.args.stepConfig) {
      return true;
    }

    return this.args.stepConfig?.showChannelTypeHeaders;
  }

  get shouldShowPreHandoverSettings() {
    if (!this.args.stepConfig) {
      return true;
    }

    return this.args.stepConfig?.showFinPreHandoverSection;
  }

  get shouldShowCsatSettings() {
    if (!this.args.stepConfig) {
      return true;
    }

    return this.args.stepConfig?.showFinCsatSection;
  }

  get shouldShowAutoCloseSettings() {
    if (!this.args.stepConfig) {
      return true;
    }

    return this.args.stepConfig?.showFinAutoCloseSection;
  }

  get channelIncompatibleWithFinCSAT() {
    let channels = this.contentEditorService.activeObject?.targetChannels || [];
    if (!channels.length) {
      return false;
    }

    let incompatibleChannels = channels.filter((channel: Channel) => {
      return !FIN_CSAT_COMPATIBLE_CHANNELS.includes(channel);
    });
    return incompatibleChannels.length;
  }

  get targetingEmail() {
    let channels = this.contentEditorService.activeObject?.targetChannels || [];
    if (!channels.length) {
      return false;
    }
    return channels.find((channel: Channel) => channel === 'email');
  }

  get answerTypeSummary() {
    if (this.args.step.useCustomAnswers) {
      if (this.args.step.useAiAnswers) {
        return this.intl.t(
          'operator.workflows.visual-builder.pass-to-fin.settings.control-answer-type.ai-and-custom-answers',
        );
      } else {
        return this.intl.t(
          'operator.workflows.visual-builder.pass-to-fin.settings.control-answer-type.custom-answers',
        );
      }
    }

    return this.intl.t(
      'operator.workflows.visual-builder.pass-to-fin.settings.control-answer-type.ai-answers',
    );
  }

  get previewConfiguration() {
    return this.store.peekRecord('messenger-settings/all', this.appService.app.id)
      ?.previewConfiguration;
  }

  @action
  toggleAiAnswers() {
    this.args.step.useAiAnswers = !this.args.step.useAiAnswers;
    if (this.args.step.useAiAnswers && this.args.step.botMode !== 'bot_only') {
      this.args.step.botMode = 'looping';
    } else if (!this.args.step.useAiAnswers) {
      this.args.step.preHandoverAnswerEnabled = false;
      if (!this.args.step.useCustomAnswers) {
        this.args.step.useCustomAnswers = true;
      }
    }
  }

  @action
  toggleCustomAnswers() {
    this.args.step.useCustomAnswers = !this.args.step.useCustomAnswers;
    if (this.args.step.useCustomAnswers && this.args.step.botMode !== 'bot_only') {
      this.args.step.botMode = 'looping';
    } else if (!this.args.step.useAiAnswers && !this.args.step.useCustomAnswers) {
      this.args.step.useAiAnswers = true;
    }
  }

  @action
  toggleOneTimeMode() {
    this.args.step.botMode = this.args.step.botMode === 'one_time' ? 'looping' : 'one_time';
    if (this.args.step.botMode === 'one_time') {
      this.args.step.preHandoverAnswerEnabled = false;
    }
  }

  @action
  toggleBotOnlyMode() {
    this.args.step.botMode = this.args.step.botMode === 'bot_only' ? 'looping' : 'bot_only';
    if (this.args.step.botMode === 'bot_only') {
      this.args.step.preHandoverAnswerEnabled = false;
    }
  }

  @action
  toggleCsatEnabled() {
    this.args.step.csatEnabled = !this.args.step.csatEnabled;

    this.syncCsatResolutionTypes();
    this.settleCsatBlockAfterUpdate();
  }

  syncCsatResolutionTypes() {
    this.args.step.csatEnabledForHardResolution = this.args.step.csatEnabled;
    this.args.step.csatEnabledForSoftResolution = this.args.step.csatEnabled;
  }

  @action
  toggleCsatEnabledForHardResolution() {
    this.args.step.csatEnabledForHardResolution = !this.args.step.csatEnabledForHardResolution;

    this.syncCsatEnabled();
    this.settleCsatBlockAfterUpdate();
  }

  @action
  toggleCsatEnabledForSoftResolution() {
    this.args.step.csatEnabledForSoftResolution = !this.args.step.csatEnabledForSoftResolution;

    this.syncCsatEnabled();
    this.settleCsatBlockAfterUpdate();
  }

  syncCsatEnabled() {
    this.args.step.csatEnabled = this.args.step.isAnyCSATSettingEnabled;
  }

  settleCsatBlockAfterUpdate() {
    if (this.args.step.isAnyCSATSettingEnabled) {
      return;
    }

    this.args.step.csatBlockUpdateAfter = undefined;
    this.showCsatBlockAfterUpdate = false;
  }

  @action
  toggleRatingChange() {
    this.showCsatBlockAfterUpdate = !this.showCsatBlockAfterUpdate;

    if (!this.showCsatBlockAfterUpdate) {
      this.args.step.csatBlockUpdateAfter = undefined;
    } else if (
      this.args.step.csatBlockUpdateAfter === undefined ||
      this.args.step.csatBlockUpdateAfter === null
    ) {
      this.args.step.csatBlockUpdateAfter = 0;
    }
  }

  @action
  setBlockUpdateAfter(e: InputEvent) {
    let value = parseInt((e.target as HTMLInputElement).value, 10);
    if (isNaN(value)) {
      value = 0;
    }
    this.args.step.csatBlockUpdateAfter = value * 3600;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::NodeItems::Steps::Fin::Settings': typeof Settings;
    'workflows/graph-editor/node-items/steps/fin/settings': typeof Settings;
  }
}
