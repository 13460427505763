/* import __COLOCATED_TEMPLATE__ from './user-profile-header.hbs'; */
/* RESPONSIBLE TEAM: team-data-foundations */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
import { and, not, or } from '@ember/object/computed';
import Component from '@ember/component';
import { ternaryToProperty } from '@intercom/pulse/lib/computed-properties';
import LocationData from 'embercom/controllers/mixins/users-and-admins/location-data';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default Component.extend(LocationData, {
  tagName: '',

  // variables that will be used in handlebar tmpl
  isAdminAndMe: and('user.isAdmin', 'user.is_me'),

  // attribute used by LocationData mixin
  modelGeoipData: ternaryToProperty('user.isAdmin', 'user.location_data', 'user.geoip_data'),

  // proxy of actions
  actions: {
    onCallClick() {
      if (this.user?.phone) {
        this.ctiService.initiateNewCall(this.user.phone);
      }
    },
  },

  get phoneCallEscalationEnabled() {
    return this.user?.phone && this.ctiService.ctiAvailable && !this.ctiService.initiatedCall;
  },

  navbarCollapsingService: service(),
  ctiService: service(),
  appService: service(),
  commonPrimaryNavBarService: service(),
  notCollapsable: not('shouldRenderHamburgerMenu'),
  collapsed: or('navbarCollapsingService.collapsed', 'notCollapsable'),

  conversationModalClosed: action(function (result) {
    if (result && result.success && this.onConversationStarted) {
      this.onConversationStarted();
    }
  }),
});
