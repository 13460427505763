/* import __COLOCATED_TEMPLATE__ from './edit.hbs'; */
/* RESPONSIBLE TEAM: team-actions */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type Action from 'embercom/models/workflow-connector/action';
import type IntlService from 'embercom/services/intl';

interface Args {
  workflowAction: Action;
  section: string;
  onOpenSectionChange: (sectionId: string) => void;
}

export default class Edit extends Component<Args> {
  @tracked selectedPreviewStyle = 'configuration';
  @tracked showCancelConfirmModal = false;
  @service intercomEventService: any;
  @service declare intl: IntlService;

  @action
  changePreviewTab(value: string) {
    this.selectedPreviewStyle = value;
  }

  get disablePreview() {
    let reason = '';

    if (!this.args.workflowAction.hasValidResponse) {
      reason = this.intl.t('workflow-connector.builder.preview.no-response-available');
    } else if (this.isActionDirty) {
      reason = this.intl.t('workflow-connector.builder.preview.action-not-saved');
    }

    return {
      isDisabled: this.isDisabled,
      reason,
    };
  }

  get isDisabled() {
    return !this.args.workflowAction.hasValidResponse || this.isActionDirty;
  }

  get isActionDirty() {
    return !!this.args.workflowAction.hasAttributeChangesThatAffectPreview;
  }

  @action
  closeCancelConfirmModal() {
    this.showCancelConfirmModal = false;
  }
}
declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'WorkflowConnector::Builder::Edit': typeof Edit;
    'workflow-connector/builder/edit': typeof Edit;
  }
}
