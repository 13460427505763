/* import __COLOCATED_TEMPLATE__ from './side-sheet-header.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import type WaitForCallback from 'embercom/models/operator/visual-builder/step/wait-for-callback';

interface Args {
  step: WaitForCallback;
}

export default class SideSheetHeader extends Component<Args> {
  @action
  onFocusOut() {
    this.args.step.name = this.args.step.name.trim();
  }

  @action
  onUpdate(newName: string) {
    this.args.step.name = newName;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::NodeItems::Steps::WaitForCallback::SideSheetHeader': typeof SideSheetHeader;
    'workflows/graph-editor/node-items/steps/wait-for-callback/side-sheet-header': typeof SideSheetHeader;
  }
}
