/* import __COLOCATED_TEMPLATE__ from './logs.hbs'; */
/* RESPONSIBLE TEAM: team-actions */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class Logs extends Component {
  @tracked selectedAction;
  @tracked filters = {
    success: 'any',
    error_type: 'any',
    created_at: 168,
  };

  constructor() {
    super(...arguments);
    this.selectedAction = this.args.workflowActionLogs.objectAt(0);
  }

  @action
  onNewFilterSelected(key, selectedValues) {
    this.filters[key] = selectedValues;
    this.args.updateFilters(this.filters);
  }
}
