/* import __COLOCATED_TEMPLATE__ from './side-sheet-body.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import type WaitForCallback from 'embercom/models/operator/visual-builder/step/wait-for-callback';

interface Arguments {
  step: WaitForCallback;
}

const STATIC_LINK = 'https://api.intercom.com/hooks/workflows/trigger_wait_step/';
const DEFAULT_REQUEST =
  '{ \n  "verification_status": "approved",\n  "user_id": "1234567890",\n  "verification_id": "abcde-12345-fghij-67890",\n  "approved_at": "2024-11-28T15:30:00Z"  \n}';

export default class SideSheetBody extends Component<Arguments> {
  @service intl!: IntlService;

  exampleRequest = this.args.step.exampleRequest || DEFAULT_REQUEST;

  constructor(owner: unknown, args: Arguments) {
    super(owner, args);
  }

  get title() {
    return this.intl.t('operator.workflows.visual-builder.wait-for-callback.title-with-name', {
      name: this.args.step.name,
    });
  }

  get webhookUrl() {
    return `${STATIC_LINK}${this.args.step.externalStepId}`;
  }

  @action
  updateExampleRequest(value: string) {
    this.args.step.exampleRequest = value;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::NodeItems::Steps::WaitForCallback::SideSheetBody': typeof SideSheetBody;
    'workflows/graph-editor/node-items/steps/wait-for-callback/side-sheet-body': typeof SideSheetBody;
  }
}
