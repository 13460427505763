/* import __COLOCATED_TEMPLATE__ from './tagging.hbs'; */
/* RESPONSIBLE TEAM: team-data-foundations */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class Tagging extends Component {
  @service modalService;

  @action
  closeModal() {
    this.modalService.closeModal();
  }
}
