/* import __COLOCATED_TEMPLATE__ from './inactivity-workflow.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import Component from '@glimmer/component';
import type Fin from 'embercom/models/operator/visual-builder/step/fin';
import type EditorState from 'embercom/objects/workflows/graph-editor/editor-state';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type Store from '@ember-data/store';
import { type ReusableWorkflow } from '../../trigger-workflow/selector';

interface Signature {
  Element: HTMLDivElement;
  Args: {
    editorState: EditorState;
    step: Fin;
    isReadOnly: boolean;
  };
}

class InactivityWorkflow extends Component<Signature> {
  @service declare store: Store;

  get step(): Fin {
    return this.args.step;
  }

  get isInactivityWorkflowEnabled() {
    return !!this.step.inactivityWorkflow;
  }

  get triggerableWorkflowId() {
    return this.step.inactivityWorkflow?.triggerableWorkflowId;
  }

  get isValid() {
    return this.args.step.validations.attrs.inactivityWorkflow.isValid;
  }

  @action
  toggleContinueWithFin() {
    if (!this.args.step.inactivityWorkflow) {
      return;
    }
    this.args.step.inactivityWorkflow.continueWithFin =
      !this.args.step?.inactivityWorkflow.continueWithFin;
  }

  @action
  toggleInactivityWorkflow() {
    if (this.isInactivityWorkflowEnabled) {
      this.step.inactivityWorkflow = null;
    } else {
      this.step.inactivityWorkflow = this.store.createFragment('operator/fin/inactivity-workflow');
    }
  }

  @action
  setSelectedWorkflow(workflow: ReusableWorkflow) {
    if (this.step.inactivityWorkflow) {
      this.step.inactivityWorkflow.triggerableWorkflowId = workflow?.value;
      this.step.inactivityWorkflow.compatibleChannels = workflow?.compatibleChannels;
    } else {
      console.error('inactivityWorkflow is not set');
    }
  }
}

export default InactivityWorkflow;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::NodeItems::Steps::Fin::Sections::InactivityWorkflow': typeof InactivityWorkflow;
    'workflows/graph-editor/node-items/steps/fin/sections/inactivity-workflow': typeof InactivityWorkflow;
  }
}
