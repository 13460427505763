/* RESPONSIBLE TEAM: team-data-foundations */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { A } from '@ember/array';
import Component from '@ember/component';
import { computed } from '@ember/object';
import { and, not, readOnly, setDiff } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { capitalize } from '@ember/string';
import { rejectBy } from '@intercom/pulse/lib/computed-properties';

export default Component.extend({
  segmentViewCollapsedService: service(),
  intl: service(),
  collapsed: readOnly('segmentViewCollapsedService.collapsed'),
  notCollapsed: not('collapsed'),
  logicalSwitcherItems: [
    {
      text: 'that match all filters',
      value: 'and',
    },
    {
      text: 'that match any filters',
      value: 'or',
    },
  ],
  numberOfFiltersToDisplayWhenCollapsed: 1,
  numberOfFiltersAtWhichToDisplayHideFilterButton: 5,
  intercomEventService: service(),
  classNames: ['flex-auto flex flex-col'],
  isUserSegment: true,
  appService: service(),
  attributeService: service(),
  // eslint-disable-next-line @intercom/intercom/no-legacy-modal
  modalService: service(),
  app: readOnly('appService.app'),
  canUseCdaCreator: and('app.currentAdmin.hasProductAccessForCurrentApp', 'isUserSegment'),
  // Note: @pat - App attributes are loaded on page load but if we want to dynamically track updates to these models over the lifetime of the app and update user/companyAttributes, we should track specific properties on the models instead.
  userAttributes: rejectBy(
    'attributeService.filterableUserAttributesMaybeWithSegmentsAndTags',
    'isUntrackedCda',
    true,
  ),
  companyAttributes: readOnly(
    'attributeService.filterableCompanyAttributesMaybeWithPlansSegmentsAndTags',
  ),
  priorityUserAttributes: computed(
    'userAttributes.[]',
    'attributeService.attributeSettings.[]',
    function () {
      let attributes = this.userAttributes;
      let attributeSettings = this.get('attributeService.attributeSettings');
      return attributes.reject(
        (attribute) =>
          !(
            attributeSettings.findBy('attribute', attribute.get('identifier')) ||
            attribute.get('requiredFilter')
          ),
      );
    },
  ),
  secondaryUserAttributes: setDiff('userAttributes', 'priorityUserAttributes'),
  sortedUserAttributes: computed(
    'priorityUserAttributes.[]',
    'secondaryUserAttributes.[]',
    function () {
      let firstSet = this.priorityUserAttributes;
      let secondSet = this.secondaryUserAttributes;
      return A(firstSet.toArray().concat(secondSet.toArray()));
    },
  ),
  attributeGroupList: computed(
    'app.companiesActive',
    'segment.userType',
    'userAttributes.[]',
    'companyAttributes.[]',
    'isUserSegment',
    function () {
      let groups = [];
      if (this.isUserSegment) {
        let segmentUserType = this.get('segment.userType');
        let heading = segmentUserType ? `${capitalize(segmentUserType)} Data` : 'Data';
        groups.push({
          heading,
          attributes: this.sortedUserAttributes,
        });
      }
      if (this.get('app.companiesActive')) {
        groups.push({
          heading: 'Company Data',
          attributes: this.companyAttributes,
        });
      }
      return groups;
    },
  ),
  orFilterLimit: 10,
  logicalSwitcherTooltip: computed('intl.locale', function () {
    return this.intl.t('users-and-companies.segment-editor.logical-switcher-tooltip');
  }),
  addFilterTooltip: computed('intl.locale', function () {
    return this.intl.t('users-and-companies.segment-editor.add-filter-tooltip');
  }),
  actions: {
    showNewDataModal() {
      this.modalService.openModal('settings/modals/attribute-details', {
        allowArchival: false,
      });
    },
    toggleCollapsedState() {
      this.segmentViewCollapsedService.toggle();
    },
    proxiedOnUpdate() {
      this.segmentViewCollapsedService.expand();
      let onUpdate = this.onUpdate;
      if (typeof onUpdate === 'function') {
        onUpdate(...arguments);
      }
    },
  },
});
