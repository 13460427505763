/* import __COLOCATED_TEMPLATE__ from './input-mapping-side-drawer.hbs'; */
/* RESPONSIBLE TEAM: team-actions */
import Component from '@glimmer/component';
import type WorkflowConnector from 'embercom/models/operator/visual-builder/step/workflow-connector';
import type WorkflowConnectorConfiguration from 'embercom/objects/visual-builder/configuration/step/workflow-connector';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type InsertableActionField from 'embercom/models/workflow-connector/insertable-action-field';
import type ActionInputParameterMapping from 'embercom/models/workflow-connector/action-input-parameter-mapping';
import { isEmpty } from '@ember/utils';

interface Arguments {
  step: WorkflowConnector;
  hideDrawer: () => void;
  stepConfig: WorkflowConnectorConfiguration;
  drawerTitle: string;
  showSideDrawer: boolean;
}

export default class InputMappingSideDrawer extends Component<Arguments> {
  @service declare appService: any;

  constructor(owner: unknown, args: Arguments) {
    super(owner, args);
    this.parameterMappingsForDisplay;
  }

  get parameterMappingsForDisplay() {
    return this.args.step.action?.requestParameters.map(
      (requestParameter: InsertableActionField) => ({
        name: requestParameter.name,
        identifier: requestParameter.identifier,
        type: requestParameter.type,
        templatedValue: this.getMappedInputTemplatedValue(requestParameter.identifier),
        onChange: this.onBodyComposerChange,
      }),
    );
  }

  findActionInputParameterMapping(requestParameterIdentifier: string) {
    return this.args.step.actionInputParameterMappings.find(
      (actionInputParameterMapping: ActionInputParameterMapping) =>
        actionInputParameterMapping.inputParameterId === requestParameterIdentifier,
    );
  }

  getMappedInputTemplatedValue(requestParameterIdentifier: string) {
    let actionInputParameterMapping = this.findActionInputParameterMapping(
      requestParameterIdentifier,
    );
    return [
      {
        type: 'paragraph',
        text: actionInputParameterMapping?.templatedValue || '',
        modelKey: 'common/blocks/paragraph',
      },
    ];
  }

  @action
  onBodyComposerChange(
    requestParameterIdentifier: string,
    newBlocksDoc: { blocks: { text: string }[] },
  ) {
    let newTemplatedValue = newBlocksDoc.blocks[0].text;
    let actionInputParameterMapping = this.findActionInputParameterMapping(
      requestParameterIdentifier,
    );

    if (actionInputParameterMapping) {
      this.args.step.actionInputParameterMappings.removeObject(actionInputParameterMapping);
    }

    if (!isEmpty(newTemplatedValue)) {
      this.args.step.actionInputParameterMappings.pushObject({
        inputParameterId: requestParameterIdentifier,
        templatedValue: newTemplatedValue,
      } as ActionInputParameterMapping);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::NodeItems::Steps::CustomAction::InputMappingSideDrawer': typeof InputMappingSideDrawer;
    'workflows/graph-editor/node-items/steps/custom-action/input-mapping-side-drawer': typeof InputMappingSideDrawer;
  }
}
