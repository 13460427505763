/* import __COLOCATED_TEMPLATE__ from './testing.hbs'; */
/* RESPONSIBLE TEAM: team-actions */
import Component from '@glimmer/component';
import type Action from 'embercom/models/workflow-connector/action';
import type Store from '@ember-data/store';
import type IntlService from 'ember-intl/services/intl';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

interface Args {
  workflowAction: Action;
  templatedParameters: any;
  clearTemplatedParameter: () => void;
  nextSection: (sectionId: string) => void;
  saveTemplatedParameter: (requestParameter: any, requestValue: any) => void;
}
export default class Testing extends Component<Args> {
  @service declare intl: IntlService;
  @service declare store: Store;
  @service declare appService: any;
  @service declare notificationsService: any;

  get isMockConfigurationSelected() {
    return this.args.workflowAction?.configurationResponseType === 'mock_response_type';
  }

  @action
  setResponseTypeToTest() {
    this.args.workflowAction.configurationResponseType = 'test_response_type';
  }

  @action
  setResponseTypeToMock() {
    this.args.workflowAction.configurationResponseType = 'mock_response_type';
  }

  @action
  previousSection() {
    this.args.nextSection('request_section');
  }

  @action
  nextSection() {
    this.args.nextSection('response_section');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'WorkflowConnector::Builder::Body::Sections::Testing': typeof Testing;
    'workflow-connector/builder/body/sections/testing': typeof Testing;
  }
}
