/* import __COLOCATED_TEMPLATE__ from './cell.hbs'; */
/* RESPONSIBLE TEAM: team-data-foundations */
import Component from '@glimmer/component';
import TableDataFormatter from 'embercom/components/users/table/table-data-formatter';
import { inject as service } from '@ember/service';

export default class UsersTableCellComponent extends Component {
  @service intl;
  /* @pat - To make sure the "Last Seen" issue field does not auto update when updating user presence, we only set the formatter on init.
  See https://github.com/intercom/intercom/issues/30291 which documents the bug it prevents. */
  formatter = TableDataFormatter.create({
    userOrCompany: this.args.user,
    attribute: this.args.attribute,
    isCompany: this.args.isCompany,
    intl: this.intl,
  });
}
