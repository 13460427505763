/* import __COLOCATED_TEMPLATE__ from './fin-custom.hbs'; */
/* RESPONSIBLE TEAM: team-ml */
import Component from '@glimmer/component';
import type FinCustom from 'embercom/models/operator/visual-builder/step/fin-custom';
import type EditorState from 'embercom/objects/workflows/graph-editor/editor-state';
import type FinCustomConfiguration from 'embercom/objects/visual-builder/configuration/step/fin-custom';
import { action } from '@ember/object';
import type AttributeDescriptor from 'embercom/models/operator/visual-builder/attribute-descriptor';
import { Type } from 'embercom/models/operator/visual-builder/attribute-descriptor';
import { tracked } from '@glimmer/tracking';

export interface OutputParam {
  id: string;
  name: string;
  description: string;
}

interface Arguments {
  step: FinCustom;
  stepConfig: FinCustomConfiguration;
  editorState: EditorState;
  readOnly: boolean;
}

export default class FinCustomNodeStep extends Component<Arguments> {
  @tracked outputParams: OutputParam[];

  constructor(owner: unknown, args: Arguments) {
    super(owner, args);

    this.outputParams = this.args.step.finOutputParams.map((outputParam: AttributeDescriptor) => {
      return { name: outputParam.name, description: outputParam.description, id: outputParam.id };
    });
  }

  get shouldShowError() {
    return this.args.editorState.shouldShowValidations && !this.args.step.validations.isValid;
  }

  @action
  addOutputParam() {
    let attributeDescriptor = this.args.editorState.createLocalVariable('', Type.string);

    this.args.step.finOutputParams.addObject(attributeDescriptor);

    this.outputParams = [
      ...this.outputParams,
      {
        name: attributeDescriptor.name,
        description: attributeDescriptor.description,
        id: attributeDescriptor.id,
      },
    ];
  }

  @action
  removeOutputParam(outputParam: OutputParam) {
    let attributeDescriptor = this.args.step.finOutputParams.findBy('id', outputParam.id);
    if (!attributeDescriptor) {
      return;
    }
    this.args.editorState.deleteLocalVariable(attributeDescriptor);
    this.outputParams = this.outputParams.filter(
      (param: OutputParam) => param.id !== outputParam.id,
    );
  }

  @action
  onSideSheetClose() {
    this.outputParams.map((outputParam: OutputParam) => {
      let attributeDescriptor = this.args.step.finOutputParams.findBy('id', outputParam.id);
      if (!attributeDescriptor) {
        return;
      }
      attributeDescriptor.name = outputParam.name;
      attributeDescriptor.description = outputParam.description;
    });
    this.args.step.notifyPropertyChange('finOutputParams');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::NodeItems::Steps::FinCustom': typeof FinCustom;
    'workflows/graph-editor/node-items/steps/fin-custom': typeof FinCustom;
  }
}
