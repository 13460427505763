/* import __COLOCATED_TEMPLATE__ from './selector.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import {
  matchBehaviors,
  objectTypes,
  states,
  permissionsMap,
} from 'embercom/models/data/matching-system/matching-constants';
import { type AllChannels, NO_TRIGGER_TARGET } from 'embercom/lib/operator/custom-bots/constants';
import Component from '@glimmer/component';
import Ruleset from 'embercom/models/matching-system/ruleset';
import { TRIGGERABLE_BOT_TYPE } from 'embercom/lib/operator/custom-bots/constants';
import { action } from '@ember/object';
import { isBlank } from '@ember/utils';
import safeWindowOpen from 'embercom/lib/safe-window-open';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import ajax from 'embercom/lib/ajax';
import { tracked } from '@glimmer/tracking';
import { intersection } from 'underscore';
import type IntlService from 'embercom/services/intl';
import type Router from '@ember/routing/router-service';
import type Store from '@ember-data/store';
import type EditorState from 'embercom/objects/workflows/graph-editor/editor-state';
import type Step from 'embercom/models/operator/visual-builder/step';
import { taskFor } from 'ember-concurrency-ts';
import { type TaskGenerator } from 'ember-concurrency';
import TriggerWorkflowStep from 'embercom/models/operator/visual-builder/step/trigger-workflow';

interface ReusableWorkflowWireFormat {
  id: string;
  title: string;
  operator_workflow_instance_id: number;
  state: number;
  user_visible: boolean;
  compatible_channels: AllChannels[];
}

export interface ReusableWorkflow {
  text: string;
  value: number;
  icon: string;
  workflowId: string;
  state: number;
  component: string;
  compatibleChannels: AllChannels[];
  userVisible: boolean;
  componentAttrs: {
    showTooltips: boolean;
    tooltipText: string | undefined;
  };
  live: boolean;
  isDisabled: boolean;
}

interface Signature {
  Element: HTMLDivElement;
  Args: {
    editorState: EditorState;
    setSelectedWorkflow: (workflow: ReusableWorkflow | undefined) => void;
    step: Step;
    readOnly: boolean;
    triggerableWorkflowId: number | string | undefined;
    isValid: boolean;
  };
}

export default class Selector extends Component<Signature> {
  @service declare appService: $TSFixMe;
  @service declare intl: IntlService;
  @service declare notificationsService: $TSFixMe;
  @service declare outboundHomeService: $TSFixMe;
  @service declare permissionsService: $TSFixMe;
  @service declare contentEditorService: $TSFixMe;
  @service declare router: Router;
  @service declare store: Store;

  @tracked workflows: ReusableWorkflow[] = [];
  @tracked targetChannels: AllChannels[] | null = null;
  @tracked selectorIsOpen = false;
  @tracked showBotCreationModal = false;
  @tracked triggerableWorkflowId: number | string | undefined;

  noTriggerObjectType = objectTypes.triggerableCustomBot;
  noTriggerTarget = NO_TRIGGER_TARGET;
  permissionKey = permissionsMap[objectTypes.triggerableCustomBot];

  constructor(owner: unknown, args: Signature['Args']) {
    super(owner, args);
    this.triggerableWorkflowId = this.args.triggerableWorkflowId;

    if (this.isOmnichannelWorkflow) {
      taskFor(this.fetchTargetChannels).perform();
    }
  }

  @task({ drop: true })
  *fetchTargetChannels(): TaskGenerator<void> {
    let activeObject = this.contentEditorService.activeObject;
    if (this.isOmnichannelCustomBot && activeObject.isNoTrigger) {
      let rootWorkflows = yield activeObject.loadRootWorkflows();
      if (rootWorkflows.length > 0) {
        this.targetChannels = intersection(...rootWorkflows.mapBy('targetChannels'));
      } else {
        // if there are no root workflows, then every reusable workflow can be selected
        this.targetChannels = null;
      }
    } else {
      this.targetChannels = activeObject.targetChannels;
    }
  }

  @task({ drop: true })
  *fetchReusableWorkflows(
    workflowIds: string[] = [],
    includeCompatibleChannels = false,
  ): TaskGenerator<ReusableWorkflowWireFormat[]> {
    return yield ajax({
      url: `/ember/operator_custom_bots/${this.args.editorState.workflowInstanceEntityId}/no_trigger_bots`,
      type: 'GET',
      data: {
        app_id: this.appService.app.id,
        workflow_ids: workflowIds,
        include_compatible_channels: includeCompatibleChannels,
      },
    });
  }

  @task({ drop: true })
  *fetchTriggerableWorkflows(): TaskGenerator<void> {
    let triggerableWorkflows: ReusableWorkflow[] = [];
    let reusableWorkflows = yield taskFor(this.fetchReusableWorkflows).perform();

    reusableWorkflows.forEach((workflow: ReusableWorkflowWireFormat) => {
      triggerableWorkflows.push({
        text:
          workflow.title ||
          this.intl.t('operator.custom-bot.editor.no-trigger-bot-selector.untitled'),
        value: workflow.operator_workflow_instance_id,
        icon: 'bot-filled',
        workflowId: workflow.id,
        state: workflow.state,
        component: 'operator-flows/editor/trigger-workflow/no-trigger-bot-option',
        compatibleChannels: workflow.compatible_channels,
        userVisible: workflow.user_visible,
        componentAttrs: {
          showTooltips:
            (this.isOmnichannelWorkflow &&
              !this.isCompatible(this.targetChannels, workflow.compatible_channels)) ||
            workflow.state !== states.live,
          tooltipText: this.getReusableWorkflowTooltipText(workflow),
        },
        live: workflow.state === states.live,
        isDisabled:
          (this.isOmnichannelWorkflow &&
            !this.isCompatible(this.targetChannels, workflow.compatible_channels)) ||
          workflow.state !== states.live,
      });
    });

    // sort by bot state in the order live, paused, draft
    if (this.isOmnichannelWorkflow) {
      let compatibleWorkflows = triggerableWorkflows.filter(
        (workflow) =>
          workflow.state === states.live &&
          this.isCompatible(this.targetChannels, workflow.compatibleChannels),
      );
      let incompatibleWorkflows = triggerableWorkflows.filter(
        (workflow) =>
          !this.isCompatible(this.targetChannels, workflow.compatibleChannels) ||
          workflow.state === states.paused ||
          workflow.state === states.draft,
      );
      this.workflows = [...compatibleWorkflows, ...incompatibleWorkflows];
    } else {
      let liveWorkflows = triggerableWorkflows.filter((workflow) => workflow.state === states.live);
      let pausedWorkflows = triggerableWorkflows.filter(
        (workflow) => workflow.state === states.paused,
      );
      let draftWorkflows = triggerableWorkflows.filter(
        (workflow) => workflow.state === states.draft,
      );
      this.workflows = [...liveWorkflows, ...pausedWorkflows, ...draftWorkflows];
    }

    // If the step has a workflow id that doesn't exist in the list of possible triggerable workflows,
    // ensure that no workflow is selected in the select component.
    // (This is defensive as embercom will crash if there is a selected value that isn't an option in our
    // select component).
    let possibleWorkflowIds = this.workflows.map((wf) => wf.value);
    if (!possibleWorkflowIds.includes(Number(this.triggerableWorkflowId))) {
      this.setTriggerableWorkflowId(undefined);
    }
  }

  isCompatible(targetChannels: AllChannels[] | null, compatibleChannels: AllChannels[] | null) {
    if (!targetChannels || !compatibleChannels) {
      return true;
    }

    return intersection(targetChannels, compatibleChannels).length === targetChannels.length;
  }

  getReusableWorkflowTooltipText(workflow: ReusableWorkflowWireFormat) {
    if (workflow.state !== states.live) {
      return this.intl.t('operator.custom-bot.editor.no-trigger-bot-selector.require-set-bot-live');
    } else if (
      this.isOmnichannelWorkflow &&
      !this.isCompatible(this.targetChannels, workflow.compatible_channels)
    ) {
      return this.intl.t(
        'operator.custom-bot.editor.no-trigger-bot-selector.contains-incompatible-steps',
        {
          channels: this.intl.formatList(
            this.targetChannels!.filter(
              (channel) => !workflow.compatible_channels.includes(channel),
            ),
            {
              style: 'long',
              type: 'conjunction',
            },
          ),
        },
      );
    }
    return undefined;
  }

  get isOmnichannelWorkflow() {
    return (
      this.contentEditorService.activeRulesetLink &&
      (this.isOmnichannelCustomBot || this.isOmnichannelResolutionBot)
    );
  }

  get isOmnichannelCustomBot() {
    return (
      this.contentEditorService.activeRulesetLink.objectType === objectTypes.triggerableCustomBot
    );
  }

  get isOmnichannelResolutionBot() {
    return (
      this.contentEditorService.activeRulesetLink.objectType === objectTypes.resolutionBotBehavior
    );
  }

  get hasWorkflows() {
    return this.workflows.length > 0;
  }

  get items() {
    if (this.loading) {
      return [
        {
          value: null,
          component: 'operator-flows/editor/trigger-workflow/loading-state',
          componentShouldReplaceItem: true,
        },
      ];
    }
    if (!this.hasWorkflows) {
      return [
        {
          value: null,
          component: 'operator-flows/editor/trigger-workflow/empty-state',
          componentShouldReplaceItem: true,
        },
      ];
    }
    return [
      {
        items: this.workflows,
      },
    ];
  }

  get loading() {
    return taskFor(this.fetchTriggerableWorkflows).isRunning;
  }

  get selectedValue() {
    return !this.loading && this.hasWorkflows ? this.triggerableWorkflowId : undefined;
  }

  get selectedWorkflow() {
    if (this.selectedValue) {
      return this.workflows.find((wf) => wf.value === Number(this.selectedValue));
    }

    return undefined;
  }

  get hasError() {
    return (!this.loading && !this.triggerableWorkflowId) || !this.args.isValid;
  }

  @action
  async createCustomBot() {
    if (!this.canCreateCustomBot()) {
      await this.permissionsService.loadAllAdminsAndShowPermissionRequestModal(this.permissionKey);
      this.toggleBotCreationModal();
      return;
    }

    let params = {
      app_id: this.appService.app.id,
      object_type: objectTypes.triggerableCustomBot,
      match_behavior: matchBehaviors.transient,
      object_data: { type: TRIGGERABLE_BOT_TYPE },
    };

    try {
      let ruleset = await Ruleset.createForType(this.store, params);
      safeWindowOpen(
        this.router.urlFor('apps.app.automation.workflows.edit', ruleset.id, {
          queryParams: { mode: 'edit' },
        }),
      );
      this.showBotCreationModal = false;
    } catch (error) {
      this.notificationsService.notifyResponseError(error, {
        default: this.intl.t(
          'operator.custom-bot.editor.no-trigger-bot-selector.could-not-create-bot',
        ),
      });
    }
  }

  @action
  toggleBotCreationModal() {
    this.showBotCreationModal = !this.showBotCreationModal;
  }

  canCreateCustomBot() {
    if (isBlank(this.permissionKey)) {
      return true;
    }

    return this.permissionsService.currentAdminCan(this.permissionKey);
  }

  @action
  async setTriggerableWorkflowId(workflowId: string | undefined) {
    this.triggerableWorkflowId = workflowId;
    if (workflowId) {
      let workflows = await taskFor(this.fetchReusableWorkflows).perform([workflowId], true);
      let workflow = this.workflows.find((wf) => wf.value === Number(workflowId));
      if (workflow) {
        workflow.compatibleChannels = workflows[0]?.compatible_channels;
        this.args.setSelectedWorkflow?.(workflow);
        // Ember has a tracking issue where updating userVisible doesn't update the UI
        this.args.step.group.notifyPropertyChange('isCustomerFacing');
      }
    } else {
      this.args.setSelectedWorkflow?.(undefined);
    }
  }

  @action
  onSelectorOpen() {
    taskFor(this.fetchTriggerableWorkflows).perform();
    this.selectorIsOpen = true;
  }

  @action
  onSelectorClose() {
    if (this.args.step instanceof TriggerWorkflowStep) {
      this.args.step.justAdded = false;
    }
    this.selectorIsOpen = false;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::NodeItems::Steps::TriggerWorkflow::Selector': typeof Selector;
    'workflows/graph-editor/node-items/steps/trigger-workflow/selector': typeof Selector;
  }
}
