/* import __COLOCATED_TEMPLATE__ from './attribute-collector.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class AttributeCollector extends Component {
  @service attributeService;

  get attributes() {
    return this.args.editorConfiguration.collectableAttributesGroupList
      .map((groupList) => groupList.attributes)
      .flat();
  }

  get attribute() {
    if (this.args.step.choiceAttributeDescriptor) {
      return this.args.step.choiceAttributeDescriptor;
    }
    return this.attributes.find(
      (attribute) => attribute.identifier === this.args.step.attributeIdentifier,
    );
  }
}
